// const MAX_PLACE_NAME_CHARS = 25;
export const MAX_PRODUCT_CHARS = 30;
export const MAX_COST_CHARS = 7;

export const htmlDecode = (input: string | undefined) => {
  if (!input) {
    return undefined;
  }

  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent ?? "";
}
