import React, { useState, useEffect } from 'react';
import { Stack, Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { signInWithEmailAndPassword } from "firebase/auth";
import { firebaseAuth } from "../util/firebase";
import { FacebookLoginButton, GoogleLoginButton, TwitterLoginButton, AppleLoginButton, GithubLoginButton } from "react-social-login-buttons";
import axios from 'axios';

import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, OAuthProvider, GithubAuthProvider } from "firebase/auth";
import { AuthProviderType, reportSignIn } from '../util/auth';
import { signInWithCustomToken } from "firebase/auth";

import { reportAction } from '../util/metrics';

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const twitterProvider = new TwitterAuthProvider();
// const appleProvider = new OAuthProvider('apple.com');
// const githubProvider = new GithubAuthProvider();

const classes = { paper: "paper", avatar: "avatar", form: "form", submit: "submit", customError: "customError", progress: "progress"}

type ErrorsType = {
  email?: string,
  // username?: string,
  password?: string,
  confirmPassword?: string,
  general?: string,
}




const authViaGoogle = async (signInComplete: () => void) => {
  try {
    const auth = getAuth();
    const result = await signInWithPopup(auth, googleProvider);

    const credential = GoogleAuthProvider.credentialFromResult(result);
    if (!credential) {
      console.error("No credential");
      return;
    }

    const user = result.user;
    if (!auth.currentUser) {
      console.error("No auth.currentUser");
      return;
    }

    const idToken = await auth.currentUser.getIdToken(false);
    localStorage.setItem('AuthToken', `Bearer ${idToken}`);
    await reportSignIn(AuthProviderType.Google, user.email || "");

    signInComplete();
  } catch (error) {
    // Handle Errors here.
    // TODO:
    console.log(`error: ${JSON.stringify(error)}`)
    // if (error instanceof ??) {
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    //   // The email of the user's account used.
    //   const email = error.customData.email;
    //   // The AuthCredential type that was used.
    //   const credential = GoogleAuthProvider.credentialFromError(error);
    //   // ...
    // }
  }
}

const authViaFacebook = async (signInComplete: () => void) => {
  try {
    const auth = getAuth();
    const result = await signInWithPopup(auth, facebookProvider);

    const credential = FacebookAuthProvider.credentialFromResult(result);
    if (!credential) {
      console.error("No credential");
      return;
    }

    const user = result.user;
    if (!auth.currentUser) {
      console.error("No auth.currentUser");
      return;
    }

    const idToken = await auth.currentUser.getIdToken(false);
    localStorage.setItem('AuthToken', `Bearer ${idToken}`);
    await reportSignIn(AuthProviderType.Facebook, user.email || "");

    signInComplete();
  } catch (error) {
    // Handle Errors here.
    // TODO:
    console.log(`error: ${JSON.stringify(error)}`)
    // if (error instanceof ??) {
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    //   // The email of the user's account used.
    //   const email = error.customData.email;
    //   // The AuthCredential type that was used.
    //   const credential = GoogleAuthProvider.credentialFromError(error);
    //   // ...
    // }
  }
}

const authViaTwitter = async (signInComplete: () => void) => {
  try {
    const auth = getAuth();
    const result = await signInWithPopup(auth, twitterProvider);

    const credential = TwitterAuthProvider.credentialFromResult(result);
    if (!credential) {
      console.error("No credential");
      return;
    }

    const user = result.user;
    if (!auth.currentUser) {
      console.error("No auth.currentUser");
      return;
    }

    const idToken = await auth.currentUser.getIdToken(false);
    localStorage.setItem('AuthToken', `Bearer ${idToken}`);
    await reportSignIn(AuthProviderType.Twitter, user.email || "");

    signInComplete();
  } catch (error) {
    // Handle Errors here.
    // TODO:
    console.log(`error: ${JSON.stringify(error)}`)
    // if (error instanceof ??) {
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    //   // The email of the user's account used.
    //   const email = error.customData.email;
    //   // The AuthCredential type that was used.
    //   const credential = GoogleAuthProvider.credentialFromError(error);
    //   // ...
    // }
  }
}

// const authViaGithub = async (signInComplete: () => void) => {
//   try {
//     const auth = getAuth();
//     const result = await signInWithPopup(auth, githubProvider);

//     const credential = GithubAuthProvider.credentialFromResult(result);
//     if (!credential) {
//       console.error("No credential");
//       return;
//     }

//     const user = result.user;
//     if (!auth.currentUser) {
//       console.error("No auth.currentUser");
//       return;
//     }

//     const idToken = await auth.currentUser.getIdToken(false);
//     localStorage.setItem('AuthToken', `Bearer ${idToken}`);
//     await reportSignIn(AuthProviderType.Github, user.email || "");

//     signInComplete();
//   } catch (error) {
//     // Handle Errors here.
//     // TODO:
//     console.log(`error: ${JSON.stringify(error)}`)
//     // if (error instanceof ??) {
//     //   const errorCode = error.code;
//     //   const errorMessage = error.message;
//     //   // The email of the user's account used.
//     //   const email = error.customData.email;
//     //   // The AuthCredential type that was used.
//     //   const credential = GoogleAuthProvider.credentialFromError(error);
//     //   // ...
//     // }
//   }
// }

// const authViaApple = async (signInComplete: () => void) => {
//   try {
//     const auth = getAuth();
//     const result = await signInWithPopup(auth, appleProvider);

//     const credential = OAuthProvider.credentialFromResult(result);
//     if (!credential) {
//       console.error("No credential");
//       return;
//     }

//     const user = result.user;
//     if (!auth.currentUser) {
//       console.error("No auth.currentUser");
//       return;
//     }

//     const idToken = await auth.currentUser.getIdToken(false);
//     localStorage.setItem('AuthToken', `Bearer ${idToken}`);
//     await reportSignIn(AuthProviderType.Apple, user.email || "");

//     signInComplete();
//   } catch (error) {
//     // Handle Errors here.
//     // TODO:
//     console.log(`error: ${JSON.stringify(error)}`)
//     // if (error instanceof ??) {
//     //   const errorCode = error.code;
//     //   const errorMessage = error.message;
//     //   // The email of the user's account used.
//     //   const email = error.customData.email;
//     //   // The AuthCredential type that was used.
//     //   const credential = GoogleAuthProvider.credentialFromError(error);
//     //   // ...
//     // }
//   }
// }



export type AuthProvidersProps = {
  showSignUp: boolean,
  signInComplete: () => void,
  showPasswordReset: () => void
}

const AuthProviders = ({ signInComplete, showSignUp, showPasswordReset }: AuthProvidersProps) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState("");
	const [errors, setErrors] = useState<ErrorsType>({});
	const [loading, setLoading] = useState(false);
  const [showingSignUp, setShowingSignUp] = useState(showSignUp);

  useEffect(() => {
    if (showingSignUp) {
      reportAction("show_sign_up_dialog", "engagement");
    } else {
      reportAction("show_sign_in_dialog", "engagement");
    }
  }, [showingSignUp])

	// const { classes } = props

	const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		setLoading(true);

    if (showingSignUp) {
      const newUserData = {
        email,
        password,
        confirmPassword,
      };

      try {
        const response = await axios.post('/api/signup', newUserData);

        // console.log(`${handleSubmit.name} - response: ${JSON.stringify(response)}`)

        const customToken = response.data.idtoken;

        if (!customToken) {
          // console.log(`${handleSubmit.name} - failed to get custom token`);
          setErrors({
            general: "Internal server error"
          });
          return;
        }

        const userCredential = await signInWithCustomToken(firebaseAuth, customToken);
        console.log(`userCredential: ${JSON.stringify(userCredential)}`);
        const jwtToken = await userCredential.user.getIdToken();
        localStorage.setItem('AuthToken', `Bearer ${jwtToken}`);;
        setLoading(false);
        signInComplete();
        // navigate('/');
      } catch (err: any) {
        setLoading(false);

        console.log(`${handleSubmit.name} - err: ${JSON.stringify(err)}`);

        // If error is from our API, handle it
        if (err.response && err.response.data) {
          // console.log(`${handleSubmit.name} - err.response.data: ${JSON.stringify(err.response.data)}`);
          setErrors(err.response.data);
          return;
        }

        // Handle error from Firebase Auth
        switch (err.code) {
          case "auth/invalid-email": {
            setErrors({
              email: "Invalid email address"
            });
            break;
          }
          // TODO:
          default: {
            // console.log(`${handleSubmit.name} - err: ${err}`);
            setErrors({
              general: err.code
            });
            break;
          }
        }
      }
    } else {
      const userData = {
        email,
        password
      };

      try {
        const userCredential = await signInWithEmailAndPassword(firebaseAuth, userData.email, userData.password);
        console.log(`userCredential: ${JSON.stringify(userCredential)}`);

        console.log();

        // const idToken = await userCredential.user.getIdToken();
        // localStorage.setItem('AuthToken', `Bearer ${idToken}`);
        setLoading(false);
        signInComplete();
        // navigate('/');
      } catch (err: any) {
        // console.log("SignIn error: " + JSON.stringify(err));
        setLoading(false);

        switch (err.code) {
          case "auth/invalid-email": {
            setErrors({
              email: "Invalid email address"
            });
            break;
          }
          // deprecated
          case "auth/user-not-found": {
            setErrors({
              email: "Email address not found"
            });
            break;
          }
          // deprecated
          case "auth/wrong-password": {
            setErrors({
              password: "Incorrect password"
            });
            break;
          }
          case "auth/invalid-login-credentials": {
            setErrors({
              general: "Incorrect email address or password"
            });
            break;
          }
          case "auth/network-request-failed": {
            setErrors({
              general: "Sign-in failed. Please check your network connection and try again."
            });
            break;
          }
          default: {
            setErrors({
              general: err.code
            });
            break;
          }
        }
      }
    }
	};

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />

			<Box className={classes.paper} sx={{
        mt: 0,
        mb: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <FacebookLoginButton onClick={() =>  authViaFacebook(signInComplete)}>
          <span>Continue with Facebook</span>
        </FacebookLoginButton>

        <GoogleLoginButton onClick={() => authViaGoogle(signInComplete)}>
          <span>Continue with Google</span>
        </GoogleLoginButton>

        <TwitterLoginButton onClick={() => authViaTwitter(signInComplete)}>
          <span>Continue with Twitter</span>
        </TwitterLoginButton>

        {/* <AppleLoginButton onClick={() => authViaApple(signInComplete)}>
          <span>Continue with Apple</span>
        </AppleLoginButton> */}

        {/* <GithubLoginButton onClick={() => authViaGithub(signInComplete)}>
          <span>Continue with Github</span>
        </GithubLoginButton> */}


        <Box mt={3}>
          <Typography
            // fontSize="0.8rem"
          >
            {showingSignUp ? "Or sign up with email:" : "Or sign in with email:"}
          </Typography>
        </Box>

				<Box component='form' className={classes.form} noValidate sx={{
          width: '100%',
          marginTop: 1
        }}>
          <TextField
            variant="outlined"
            margin="dense"
            // required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            // autoFocus
            helperText={errors.email}
            error={errors.email ? true : false}
            onChange={ (e) => setEmail(e.target.value) }
          />
          <TextField
            variant="outlined"
            margin="dense"
            // required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            helperText={errors.password}
            error={errors.password ? true : false}
            onChange={ (e) => setPassword(e.target.value) }
          />
          {showingSignUp &&
            <TextField
              variant="outlined"
              margin="dense"
              // required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              autoComplete="current-password"
              helperText={errors.confirmPassword}
              error={errors.confirmPassword ? true : false}
              onChange={ (e) => setConfirmPassword(e.target.value) }
            />
          }
          { errors.general && (
            <Typography variant="body2" className={ classes.customError } sx={{
              color: 'red',
              fontSize: '0.8rem',
              marginTop: 0
            }} >
              { errors.general }
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            // className={classes.submit}
            onClick={handleSubmit}
            disabled={ loading || !email || !password}
            sx={{
              mt: 3,
              mb: 2,
            }}
          >
            {showingSignUp ? "Sign Up" : "Sign In"}
            {loading && <CircularProgress size={30} className={classes.progress} sx={{
              position: 'absolute'
            }} />}
          </Button>

          <Stack>
            {/* <Box>
              <Link component="button" onClick={() => showSignUp()} variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Box> */}
            {showingSignUp &&
              <Button variant="text" onClick={() => setShowingSignUp(false)} sx={{
                textTransform: 'none'
              }}>
                {"Already have an account? Sign In"}
              </Button>
            }
            {!showingSignUp &&
              <Button variant="text" onClick={() => setShowingSignUp(true)} sx={{
                textTransform: 'none'
              }}>
                {"Don't have an account? Sign Up"}
              </Button>
            }
            {/* <Button variant="text" onClick={() => setShowingSignUp(true)} sx={{
              textTransform: 'none'
            }}>
              {"Don't have an account? Sign Up"}
            </Button> */}
            {!showingSignUp &&
              <Button variant="text" onClick={showPasswordReset} sx={{
                textTransform: 'none'
              }}>
                {"Forgot your password?"}
              </Button>
            }
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}

export default AuthProviders;
