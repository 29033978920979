import * as React from 'react';
import { Tab, Tabs, AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Button, Tooltip, MenuItem, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import Icon from '@mui/material/Icon';

const logo: string = require("./images/logo_transparent_white.svg").default;

const Logo = () => (
  <Icon sx={{display: 'flex'}}>
    <img src={logo} height={25} width={25} alt="logo"/>
  </Icon>
)

function samePageLinkNavigation(
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

type NavbarProps = {
  isSignedIn: boolean;
  navCallback: (navAction: string) => void;
  selectedTab: number | boolean;
  setSelectedTab: (tabIndex: number | boolean) => void;
}

const Navbar = ({ isSignedIn, navCallback, selectedTab, setSelectedTab }: NavbarProps) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNav = (page: string) => {
    handleCloseNavMenu();
    // console.log("page: " + page);
    switch (page.toLowerCase()) {
      case "home":
        navCallback('home');
        break;
      case "submit":
        navCallback('submit');
        break;
      case "search":
        navCallback('search');
        break;
      case "blog":
        navCallback('blog');
        break;
      case "account":
        navCallback('account');
        break;
      case "dashboard":
        navCallback('dashboard');
        break;
      case "about":
        navCallback('about');
        break;
      case "sign in":
        navCallback('sign in');
        break;
      case "create account":
        navCallback('create account');
        break;
      case "sign out":
        navCallback('sign out');
        break;
     }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== 'click' ||
      (event.type === 'click' &&
        samePageLinkNavigation(
          event as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        ))
    ) {
      // setValue(newValue);
      setSelectedTab(newValue);
    }
  };

  const tinyScreen = useMediaQuery('(max-width:359px)');
  const pages = tinyScreen ? ['Search', 'Submit'] : ['Search', 'Submit', 'Blog'];
  const signedInSettings = tinyScreen ? ['Blog', 'Dashboard', 'Account', 'About', 'Sign Out'] : ['Dashboard', 'Account', 'About', 'Sign Out'];
  const signedOutSettings = tinyScreen ? ['Blog', 'About', 'Create Account', 'Sign In'] : ['About', 'Create Account', 'Sign In'];
  const settings = isSignedIn ? signedInSettings : signedOutSettings;

  return (
    <AppBar position="fixed" sx={{
      justifyContent: "center",
      height: "68px"
    }}>
      <Container maxWidth="md">
        <Toolbar
          disableGutters
        >
          <Button
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              pl: 0,
              color: '#fff',
              textTransform: 'none',
              minWidth: 0
            }}
            onClick={() => handleNav('home')}
          >
            <Box
              sx={{
                marginRight: 1
              }}
              display={"flex"}
              alignItems={"center"}
            >
              <Logo/>
            </Box>
            <Typography
              variant="h6"
              noWrap
              color="#fff"
              // component="a"
              // href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: 'none', sm: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.1rem',
                textDecoration: 'none',
              }}
              // onClick={() => handlePageNav('home')}
            >
              BillDB
            </Typography>
          </Button>

          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="nav tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#fff"
                }
              }}
              sx={{
                ".MuiTab-root": {
                  color: "#fff !important",
                },
              }}
            >
              {pages.map((page) => (
                <Tab
                  key={page}
                  label={page}
                  onClick={() => handleNav(page)}
                />
              ))}
            </Tabs>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenNavMenu}
                sx={{ p: 0 }}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleNav(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
