import React, { useState, useEffect } from 'react';
import { IconButton, InputAdornment, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import NearMeIcon from '@mui/icons-material/NearMe';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

///const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];

  place_id: string;
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
}

export type GooglePlace = {
  placeId?: string;
  geometry?: google.maps.places.PlaceGeometry;
  name?: string;
}

export type PlaceAutocompleteProps = {
  placeNameMain: string;
  placeNameSecondary: string;
  setPlaceNameMain: (newPlaceNameMain: string) => void;
  setPlaceNameSecondary: (newPlaceNameSec: string) => void;
  selectedGooglePlace: GooglePlace | undefined;
  setSelectedGooglePlace: (newSelectedGooglePlace: GooglePlace) => void;
  businessOnly?: boolean;
  autocompleteService: any;
  setHeaderPlaceName?: (newHeaderPlaceName: string) => void;
}

const PlaceAutocomplete = ({ placeNameMain, placeNameSecondary, setPlaceNameMain, setPlaceNameSecondary, selectedGooglePlace, setSelectedGooglePlace, businessOnly, autocompleteService, setHeaderPlaceName }: PlaceAutocompleteProps) => {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const [showPlaceSpinner, setShowPlaceSpinner] = useState(false);

  // This clears the input field when the parent clears the place (after submission)
  useEffect(() => {
    if (placeNameMain.length === 0) {
      setValue(null);
    }
  }, [placeNameMain])


  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => resolve(position),
        error => reject(error)
      )
    })
  }

  const getPlaceFromLatLng = async (latLng: google.maps.LatLng): Promise<google.maps.GeocoderResult> => {
    const geocoder = new window.google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode(
        {location: latLng},
        (results, status) => {
          if (status === 'OK' && results && results.length > 0) {
            resolve(results[0]);
          }
        }
      )
    })
  }

  const handleClickCurrentLocation = async () => {
    try {
      setShowPlaceSpinner(true);

      const position: GeolocationPosition = await getCurrentPosition() as GeolocationPosition;
      const latlng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
      const place = await getPlaceFromLatLng(latlng);

      const newPlace = {
        placeId: place.place_id,
        geometry: place.geometry,
        name: "",
      }

      // console.log("handleClickCurrentLocation - " + JSON.stringify(place));

      setSelectedGooglePlace(newPlace);
      setPlaceNameMain("Current Location");
      if (setHeaderPlaceName) {
        const route = place.address_components.find(elem => elem.types.includes("route"));
        setHeaderPlaceName(route?.short_name ?? "you");
      }
      setShowPlaceSpinner(false);


      setValue({
        description: "current location",
        structured_formatting: {
          main_text: "main",
          secondary_text: "secondary",
          place_id: place.place_id
        },
        place_id: place.place_id
      });
    } catch (err) {
      console.log("Caught err: " + err);
      enqueueSnackbar("Your browser isn't sharing your location. Please enter a location manually.", { variant: 'warning' });
      setShowPlaceSpinner(false);
    }
  }

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: {
            input: string,
            types: string[] | undefined
          },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          // console.log("request: " + JSON.stringify(request));
 ///         (autocompleteService.current as any).getPlacePredictions(
          autocompleteService.getPlacePredictions(
            request,
            callback,
          );
        },
        400,
      ),
///    [],
    [autocompleteService],
  );

  const handlePlaceSelected = async (description?: string, placeId?: string) => {
    if (placeId) {
      const geocoder = new window.google.maps.Geocoder();
      await new Promise((resolve, reject) => {
        geocoder.geocode(
          {placeId: placeId},
          (results, status) => {
            if (status === 'OK' && results && results.length > 0) {
              // console.log(`handlePlaceSelected: ${JSON.stringify(results[0])}`);
              setSelectedGooglePlace({
                name: description,
                placeId: placeId,
                geometry: results[0].geometry
              });
            }
          }
        )
      })
    }

    setSelectedGooglePlace({
      name: description,
      placeId: placeId,
      geometry: {}
    });

  }


  React.useEffect(() => {
    let active = true;

    // if (!autocompleteService.current && (window as any).google) {
    //   autocompleteService.current = new (
    //     window as any
    //   ).google.maps.places.AutocompleteService();
    // }

    // if (!autocompleteService.current) {
    //   return undefined;
    // }

    if (!autocompleteService) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }


    const types: string[] | undefined = businessOnly ? ['establishment'] : undefined;
    fetch(
      {
        input: inputValue,
        types: types
      }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, businessOnly, autocompleteService]);

  return (
    <Box>
      <SnackbarProvider maxSnack={1} />
      <Autocomplete
        fullWidth
        id="place-autocomplete"
        sx={{  }}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        blurOnSelect
        // noOptionsText="No locations"
        noOptionsText={businessOnly ? "Enter a business" : "Enter a location"}
        onChange={(event: any, newValue: PlaceType | null) => {
          // console.log(`placeAutocomplete - newValue: ${JSON.stringify(newValue)}`);
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          setPlaceNameMain(newValue?.structured_formatting.main_text ?? "");
          setPlaceNameSecondary(newValue?.structured_formatting.secondary_text ?? "");
          if (setHeaderPlaceName) {
            setHeaderPlaceName(newValue?.structured_formatting.main_text ?? "you");
          }
          handlePlaceSelected(newValue?.description, newValue?.place_id);
        }}
        onInputChange={(event, newInputValue) => {
          // console.log("placeAutocomplete - onInputChange");
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            // label={businessOnly ? "Business" : "Location or Business" }
            label={businessOnly ? "Business" : "Location or Business" }
            fullWidth
            InputProps={{
              ...params.InputProps,
              // placeholder: businessOnly ? "" : "current location",
              ...(!businessOnly && {
                startAdornment: (
                  <InputAdornment
                    position="start"
                  >
                    {!showPlaceSpinner &&
                      <IconButton
                        aria-label="location"
                        onClick={() => {
                          handleClickCurrentLocation();
                        }}
                      >
                        <NearMeIcon color="primary" />
                      </IconButton>
                    }
                    {showPlaceSpinner &&
                      <CircularProgress size={40} sx={{
                        p: 1
                      }} />
                    }
                  </InputAdornment>
                )
              })
            }}
            InputLabelProps={{
              shrink: true
            }}
            sx={{ boxShadow: "0 2px 10px #00000020" }}
          />
        )}
        renderOption={(props, option) => {
          const matches = option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length]),
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </Box>
  );
}

export default PlaceAutocomplete;
