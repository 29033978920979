import React, { useEffect } from 'react';
import { Box, Container, Typography, List, ListItem, Link } from '@mui/material';
import * as metrics from "../util/metrics";

const classes = { content: "content"}

const Terms = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    metrics.reportPageLoad("Terms", window.location.href,  window.location.pathname);
  }, [])

  return (
    <Box component="main" className={classes.content} sx={{
      flexGrow: 1,
      // p: 2,
      marginTop: 2,
      width: "100%"
    }}>
      <Container
        maxWidth='md'
      >
        <Typography component="h2" variant="h5" mt={3} mb={1}>
           Terms of Service
        </Typography>
<Typography component="p" mt={1}>
Last Updated on October 26, 2023.
</Typography>
<Typography component="p" mt={1}>
These Terms of Service (the "Terms") govern your access to and use of BillDB (the “Service”).
By accessing or using the Service, you are agreeing to these Terms, which form a legally binding contract with BillDB.
Do not access or use the Service if you are unwilling or unable to be bound by the Terms.
</Typography>
<Typography component="p" mt={1}>
DEFINITIONS
</Typography>
<Typography component="p" mt={1}>
<b>Parties.</b>
“You” and “your” refer to you, as a user of the Service.
A “user” is someone who accesses or in any way uses the Service.
“We,” “us,” and “our” refer to Verdant Labs, LLC.
</Typography>
<Typography component="p" mt={1}>
<b>Content.</b>
“Content” means text, images, and all other forms of data or communication.
“Your Content” means Content that you submit or transmit to, through, or in connection with the Service, such as product and service descriptions, prices, ratings, reviews, and information that you contribute to your user profile.
“User Content” means Content that users submit or transmit to, through, or in connection with the Service.
“BillDB Content” means Content that we create and make available in connection with the Service.
“Service Content” means all of the Content that is made available in connection with the Service, including Your Content, User Content, and BillDB Content.
</Typography>
<Typography component="p" mt={1}>
CHANGES TO THE TERMS
</Typography>
<Typography component="p" mt={1}>
We may modify the Terms from time to time.
The most current version of the Terms will be located here.
You understand and agree that your access to or use of the Service is governed by the Terms effective at the time of your access to or use of the Service.
If we make material changes to these Terms, we will notify you by email, by posting notice on the Service, and/or by other method prior to the effective date of the changes.
We will also indicate at the top of this page the date that such changes were last made.
You should revisit these Terms on a regular basis as revised versions will be binding on you.
You understand and agree that your continued access to or use of the Service after the effective date of changes to the Terms represents your acceptance of such changes.
</Typography>
<Typography component="p" mt={1}>
USING THE SERVICE
</Typography>
<Typography component="p" mt={1}>
<b>Eligibility.</b>
To access or use the Service, you must have the requisite power and authority to enter into these Terms.
You may not access or use the Service if you are a competitor of BillDB or if we have previously banned you from the Service or closed your Account.
</Typography>
<Typography component="p" mt={1}>
<b>Permission to Use the Service.</b>
We grant you permission to use the Service subject to these Terms.
Your use of the Service is at your own risk, including the risk that you might be exposed to Content that is offensive, indecent, inaccurate, objectionable, incomplete, fails to provide adequate warning about potential risks or hazards, or is otherwise inappropriate.
</Typography>
<Typography component="p" mt={1}>
<b>Service Availability.</b>
The Service may be modified, updated, interrupted, suspended or discontinued at any time without notice or liability.
</Typography>
<Typography component="p" mt={1}>
<b>Accounts.</b>
You must create an Account and provide certain information about yourself in order to use some of the features that are offered through the Service.
You are responsible for maintaining the confidentiality of your Account password.
You are also responsible for all activities that occur in connection with your Account.
You agree to notify us immediately of any unauthorized use of your Account.
We reserve the right to close your Account at any time for any or no reason.
Your Account is for your personal, non-commercial use only, and you may not create or use an Account for anyone other than yourself.
We ask that you provide complete and accurate information about yourself when creating an Account in order to bolster your credibility as a contributor to the Service.
You may not impersonate someone else, provide an email address other than your own, create multiple Accounts, or transfer your Account to another person without BillDB's prior approval.
</Typography>
<Typography component="p" mt={1}>
<b>Communications from BillDB and Others.</b>
By accessing or using the Service, you consent to receive communications from other users and BillDB through the Service, or through any other means such as emails, push notifications, and text messages (including SMS and MMS).
</Typography>
<Typography component="p" mt={1}>
CONTENT
</Typography>
<Typography component="p" mt={1}>
<b>Responsibility for Your Content.</b>
</Typography>
<Typography component="p" mt={1}>
You alone are responsible for Your Content, and once posted to BillDB, it cannot always be withdrawn.
You assume all risks associated with Your Content, including anyone's reliance on its quality, accuracy, or reliability, and any risks associated with personal information you disclose.
You represent that you own or have the necessary permissions to use and authorize the use of Your Content as described herein.
You may not imply that Your Content is in any way sponsored or endorsed by BillDB.
</Typography>
<Typography component="p" mt={1}>
You may expose yourself to liability if, for example, Your Content contains material that is false, intentionally misleading, or defamatory; violates any third-party right, including any copyright, trademark, service mark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms minors; violates or advocates the violation of any law or regulation; or violates these Terms.
</Typography>
{/* <Typography component="p" mt={1}>
Our Right to Use Your Content.
</Typography>
<Typography component="p" mt={1}>
We may use Your Content in a number of different ways, including by publicly displaying it, reformatting it, incorporating it into advertisements and other works, creating derivative works from it, promoting it, distributing it, and allowing others to do the same in connection with their own websites and media platforms (“Other Media”).
As such, you hereby irrevocably grant us world-wide, perpetual, non-exclusive, royalty-free, assignable, sublicensable, transferable rights to use Your Content for any purpose.
Please note that you also irrevocably grant the users of the Service and any Other Media the right to access Your Content in connection with their use of the Service and any Other Media.
Finally, you irrevocably waive, and cause to be waived, against BillDB and its users any claims and assertions of moral rights or attribution with respect to Your Content.
By “use” we mean use, copy, publicly perform and display, reproduce, distribute, modify, translate, remove, analyze, commercialize, and prepare derivative works of Your Content.
</Typography> */}
<Typography component="p" mt={1}>
<b>Ownership.</b>
As between you and BillDB, you own Your Content.
We own the BillDB Content, including but not limited to visual interfaces, interactive features, graphics, design, compilation (including, but not limited to, our selection, coordination, aggregation, and arrangement of User Content and other Service Content), computer code, products, software, aggregate star ratings, and all other elements and components of the Service excluding Your Content, User Content and Third Party Content.
We also own the copyrights, trademarks, service marks, trade names, trade secrets, and other intellectual and proprietary rights throughout the world associated with the BillDB Content and the Service, which are protected by copyright, trade dress, patent, trademark, and trade secret laws and all other applicable intellectual and proprietary rights and laws.
As such, you may not sell, license, copy, publish, modify, reproduce, distribute, create derivative works or adaptations of, publicly display or in any way use or exploit any of the BillDB Content in whole or in part except as expressly authorized by us.
Except as expressly and unambiguously provided herein, we do not grant you any express or implied rights, and all rights in and to the Service and the BillDB Content are retained by us.
</Typography>
{/* <Typography component="p" mt={1}>
Advertising.
</Typography>
<Typography component="p" mt={1}>
BillDB and its licensees may publicly display advertisements, paid content, and other information nearby or in association with Your Content.
You are not entitled to any compensation for such advertisements.
The manner, mode and extent of such advertising are subject to change without specific notice to you.
</Typography> */}
<Typography component="p" mt={1}>
<b>Other.</b>
User Content (including any that may have been created by users employed or contracted by BillDB) does not necessarily reflect the opinion of BillDB.
Except as required by law, we have no obligation to retain or provide you with copies of Your Content, and we do not guarantee any confidentiality with respect to Your Content.
We reserve the right to remove, screen, edit, or reinstate User Content at our sole discretion for any reason or no reason, and without notice to you.
For example, we may remove a posting if we believe it violates our Content Guidelines.
</Typography>
<Typography component="p" mt={1}>
REPRESENTATIONS AND WARRANTIES
</Typography>
<Typography component="p" mt={1}>
We are under no obligation to enforce the Terms on your behalf against another user.
While we encourage you to let us know if you believe another user has violated the Terms, we reserve the right to investigate and take appropriate action at our sole discretion.
</Typography>
<Typography component="p" mt={1}>
You represent and warrant that:
You have read and understood our Content Guidelines;
You have read and understood our Privacy Policy. If you use the Service outside of the United States of America, you consent to having your personal data transferred to and processed in the United States of America.
You also represent and warrant that you will not, and will not assist, encourage, or enable others to use the Service to:
Violate our Terms, including the Content Guidelines and Event Terms and Conditions;
Post any fake or defamatory product or service information, pricing, trade submissions with others, or compensate someone or be compensated to post, refrain from posting, or remove a post;
Violate any third party's rights, including any breach of confidence, copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right;
Threaten, stalk, harm, or harass others, or promote bigotry or discrimination;
Promote a business or other commercial venture or event, or otherwise use the Service for commercial purposes;
Send bulk emails, surveys, or other mass messaging, whether commercial in nature or not; engage in keyword spamming, or otherwise attempt to manipulate the Service's search results or any third party website;
Solicit personal information from minors, or submit or transmit pornography;
Violate any applicable law;
Modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or adaptations of, publicly display, sell, trade, or in any way exploit the Service or Service Content (other than Your Content), except as expressly authorized by BillDB;
Use any robot, spider, Service search/retrieval application, or other automated device, process or means to access, retrieve, copy, scrape, or index any portion of the Service or any Service Content, except as expressly permitted by BillDB (for example, as described at www.BillDB.com/robots.txt);
Reverse engineer any portion of the Service, unless applicable law prohibits this restriction, in which case you agree to provide us with  30 days' prior written notice here;
Remove or modify any copyright, trademark, or other proprietary rights notice that appears on any portion of the Service or on any materials printed or copied from the Service;
Record, process, or mine information about users;
Access, retrieve or index any portion of the Service for purposes of constructing or populating a searchable database of business products, pricing, or reviews;
Reformat or frame any portion of the Service;
Take any action that imposes, or may impose, in our sole discretion, an unreasonable or disproportionately large load on BillDB's technology infrastructure or otherwise make excessive traffic demands of the Service;
Attempt to gain unauthorized access to the Service, Accounts, computer systems or networks connected to the Service through hacking, password mining or any other means;
Use the Service or any Service Content to transmit any computer viruses, worms, defects, Trojan horses, malicious code, spyware, malware or other items of a destructive or harmful nature;
Use any device, software or routine that interferes with the proper working of the Service, or otherwise attempt to interfere with the proper working of the Service;
Use the Service to violate the security of any computer network, crack passwords or security encryption codes; disrupt or interfere with the security of, or otherwise cause harm to, the Service or Service Content; or
Remove, circumvent, disable, damage or otherwise interfere with any security-related features of the Service, features that prevent or restrict the use or copying of Service Content, or features that enforce limitations on the use of the Service.
</Typography>
<Typography component="p" mt={1}>
ADDITIONAL POLICIES AND TERMS
</Typography>
<Typography component="p" mt={1}>
<b>Additional Terms.</b>
Your use of the Service is subject to any and all additional terms, policies, rules, or guidelines that we may post on or link to from the Service (the “Additional Terms”).
All such Additional Terms are hereby incorporated by reference into, and made a part of, these Terms.
</Typography>
<Typography component="p" mt={1}>
SUGGESTIONS AND IMPROVEMENTS</Typography>
<Typography component="p" mt={1}>
By sending us any ideas, suggestions, documents or proposals (“Feedback”), you agree that (i) your Feedback does not contain any third party confidential or proprietary information, (ii) we are under no obligation of confidentiality, express or implied, with respect to the Feedback, (iii) we may have something similar to the Feedback already under consideration or in development, (iv) we have no obligation to review, consider, or implement the Feedback, or to return to you all or part of the Feedback, and (v) you grant us an irrevocable, non-exclusive, royalty-free, perpetual, worldwide, assignable, sublicensable, transferable license to use, modify, prepare derivative works of, publish, distribute and sublicense the Feedback, and you irrevocably waive, and cause to be waived, against BillDB and its users any claims and assertions of any moral rights contained in such Feedback.
</Typography>
<Typography component="p" mt={1}>
INDEMNITY
</Typography>
<Typography component="p" mt={1}>
You agree to indemnify, defend, and hold harmless BillDB and its parents, officers, directors, employees, agents, contractors and representatives (collectively, the “BillDB Entities”) from  and against any and all third party claims, actions, demands, losses, damages, costs, liabilities and expenses (including but not limited to attorneys' fees and court costs) arising out of or relating to: (i) your access to or use of the Service, including Your Content, (ii) your violation of the Terms, (iii) your breach of your representations and warranties provided under these Terms, (iv) any products or services purchased or obtained by you in connection with the Service, (v) your products or services, or the marketing or provision thereof to end users, or (vi) the infringement by you, or any third party using your Account, of any intellectual property or other right of any person or entity.
BillDB reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims.
You agree not to settle any such matter without the prior written consent of BillDB.
BillDB will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.
</Typography>
<Typography component="p" mt={1}>
DISCLAIMERS AND LIMITATIONS OF LIABILITY
</Typography>
<Typography component="p" mt={1}>
PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF THE BILLDB ENTITIES TO YOU.
EACH OF THE SUBSECTIONS BELOW ONLY APPLIES UP TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
NOTHING HEREIN IS INTENDED TO LIMIT ANY RIGHTS YOU MAY HAVE WHICH MAY NOT BE LAWFULLY LIMITED.
BY ACCESSING OR USING THE SERVICE, YOU REPRESENT THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO THESE TERMS, INCLUDING THIS SECTION.
YOU ARE GIVING UP SUBSTANTIAL LEGAL RIGHTS BY AGREEING TO THESE TERMS.
THE SERVICE AND SERVICE CONTENT ARE MADE AVAILABLE TO YOU ON AN “AS IS”, “WITH ALL FAULTS” AND “AS AVAILABLE” BASIS, WITH THE EXPRESS UNDERSTANDING THAT THE BILLDB ENTITIES MAY NOT MONITOR, CONTROL, OR VET USER CONTENT OR THIRD PARTY CONTENT.
AS SUCH, YOUR USE OF THE SERVICE IS AT YOUR OWN DISCRETION AND RISK.
THE BILLDB ENTITIES MAKE NO CLAIMS OR PROMISES ABOUT THE QUALITY, COMPLETENESS, ACCURACY, OR RELIABILITY OF THE SERVICE, ITS SAFETY OR SECURITY, INCLUDING WITHOUT LIMITATION THE SECURITY OF YOUR DATA, OR THE SERVICE CONTENT.
ACCORDINGLY, THE BILLDB ENTITIES ARE NOT LIABLE TO YOU FOR ANY PERSONAL INJURY, LOSS OR DAMAGE THAT MIGHT ARISE, FOR EXAMPLE, FROM THE SERVICE'S INOPERABILITY, DEPLETION OF BATTERY POWER OR OTHER IMPAIRMENT OF DEVICES USED TO ACCESS THE SERVICE, SERVICE UNAVAILABILITY, SECURITY VULNERABILITIES OR FROM YOUR RELIANCE ON THE QUALITY, ACCURACY, OR RELIABILITY OF THE PRICES, PRODUCT AND SERVICE DESCRIPTIONS, RATINGS, REVIEWS (INCLUDING THEIR CONTENT OR OMISSION OF CONTENT, ORDER, AND DISPLAY), METRICS OR OTHER CONTENT FOUND ON, USED ON, OR MADE AVAILABLE THROUGH THE SERVICE.
YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE SERVICE, RELATED SERVICES, OR ANY OTHER GRIEVANCE SHALL BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO, OR USE OF THE SERVICE.
THE BILLDB ENTITIES' MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU SUFFER IN CONNECTION WITH THE SERVICE OR THESE TERMS IS LIMITED TO THE GREATER OF (i) THE AMOUNT PAID, IF ANY, BY YOU TO THE BILLDB ENTITIES IN CONNECTION WITH THE SERVICE IN THE 12 MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY, OR (ii) $100.
THE WAIVERS AND LIMITATIONS SPECIFIED IN THIS SECTION 12 WILL SURVIVE AND APPLY REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE.
</Typography>
<Typography component="p" mt={1}>
ARBITRATION, DISPUTES, AND CHOICE OF LAW
</Typography>
<Typography component="p" mt={1}>
If you are a resident of the United States or Canada:
EXCEPT FOR EXCLUDED CLAIMS: ANY CLAIM, CAUSE OF ACTION, REQUEST FOR RELIEF OR DISPUTE THAT MIGHT ARISE BETWEEN YOU AND BillDB (“CLAIMS”) MUST BE RESOLVED BY ARBITRATION ON AN INDIVIDUAL BASIS; YOU AND WE AGREE THAT EACH MAY BRING OR PARTICIPATE IN CLAIMS AGAINST THE OTHER ONLY IN OUR RESPECTIVE INDIVIDUAL CAPACITIES, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. UNLESS BOTH YOU AND BillDB AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN THE CLAIMS OF OTHER PERSONS OR PARTIES WHO MAY BE SIMILARLY SITUATED, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. YOU AND BillDB EXPRESSLY WAIVE THE RIGHT TO TRIAL BY A JURY.
“Excluded Claims” means: (a) Claims brought by you or BillDB that could be brought in small claims court, if permitted by the rules of that court, or (b) Claims related to intellectual property (like copyrights and trademarks), violations of BillDB's API Terms of Use (which, for clarity, are governed by those terms), or a breach of Section 7 above (Representations and Warranties). Excluded Claims may be brought in court. Any issues relating to the scope and enforceability of the arbitration provision will be resolved by the arbitrator. If any Claim cannot be arbitrated in accordance with this provision, then only that Claim may be brought in court and all other Claims remain subject to arbitration. Notwithstanding this paragraph, Business Claims, as defined in Section 3 of the Additional Terms for Business Accounts, are governed by that section.
Excluded Claims and Claims that cannot be arbitrated must be brought in court. California law will govern these Terms (to the extent not preempted or inconsistent with federal law), as well as any such Excluded Claim or Claim that cannot be arbitrated, without regard to conflict of law provisions. You or BillDB may seek relief in any small claims court of competent jurisdiction. All other Excluded Claims and Claims that cannot be arbitrated are subject to the exclusive jurisdiction in, and the exclusive venue of, the state and federal courts located within San Francisco County, California and you consent to the personal jurisdiction of these courts for the purpose of litigating any such Claim.
Arbitration shall be administered by the American Arbitration Association (“AAA”) in accordance with its Consumer Arbitration Rules then in effect. For more information, visit www.adr.org. Arbitration may be conducted in person, through the submission of documents, by phone or online. The arbitrator may award damages to you individually as a court could, including declaratory or injunctive relief, but only to the extent required to satisfy your individual claim.
Unless the arbitrator finds the arbitration was frivolous or brought for an improper purpose, BillDB will pay all filing, AAA, and arbitrator's fees and expenses. We waiveany right to seek an award of attorneys' fees and expenses in connection with any non-frivolous arbitration between you and us.
If you are a resident of a country located in the EEA or Switzerland, Irish law will govern these Terms, as well as any dispute that might arise between you and BillDB.
You agree that any subpoena, third-party discovery request, or other third-party process directed to BillDB must issue from, or be domesticated by, the state or federal courts located within San Francisco County, California and you agree to submit to the jurisdiction of each of these courts for any related proceedings.
</Typography>
<Typography component="p" mt={1}>
TERMINATION
</Typography>
<Typography component="p" mt={1}>
You may terminate the Terms at any time by closing your Account, discontinuing any access to or use of the Service, and providing BillDB with a notice of termination here.
We may close your Account, suspend your ability to use certain portions of the Service, terminate any license or permission granted to you hereunder, and/or ban you altogether from the Service for any or no reason, and without notice or liability of any kind.
Any such action could prevent you from accessing your Account, the Service, Your Content, Service Content, or any other related information.
</Typography>
<Typography component="p" mt={1}>
GENERAL TERMS
</Typography>
<Typography component="p" mt={1}>
We reserve the right to modify, update, or discontinue the Service at our sole discretion, at any time, for any or no reason, and without notice or liability.
Except as otherwise stated above, nothing herein is intended, nor will be deemed, to confer rights or remedies upon any third party.
The Terms contain the entire agreement between you and us regarding the use of the Service, and supersede any prior agreement between you and us on such subject matter.
The parties acknowledge that no reliance is placed on any representation made but not expressly contained in these Terms.
Any failure on BillDB's part to exercise or enforce any right or provision of the Terms does not constitute a waiver of such right or provision.
The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.
The Terms may not be waived, except pursuant to a writing executed by BillDB.
If any provision of the Terms is found to be unenforceable or invalid by an arbitrator or court of competent jurisdiction, then only that provision shall be modified to reflect the parties' intention or eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force and effect and enforceable.
The Terms, and any rights or obligations hereunder, are not assignable, transferable or sublicensable by you except with BillDB's prior written consent, but may be assigned or transferred by us without restriction. Any attempted assignment by you shall violate these Terms and be void.
You agree that no joint venture, partnership, employment, agency, special or fiduciary relationship exists between you and BillDB as a result of these Terms or your use of the Service.
The section titles in the Terms are for convenience only and have no legal or contractual effect.
</Typography>
<Typography component="p" mt={1}>
Copyright © 2023 Verdant Labs, LLC, Seattle, WA 98107, U.S.A.
        </Typography>
      </Container>
    </Box>
  );
}

export default Terms;