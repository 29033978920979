import { getCountryCode, getCountryData, getCountryDataList, getEmojiFlag } from 'countries-list';
import type {
  ICountry,
  ICountryData,
  ILanguage,
  TContinentCode,
  TCountryCode,
  TLanguageCode,
} from 'countries-list'
import { continents, countries, languages } from 'countries-list'


export const formatCost = (locale: string, currencyCode: string, cost: number) => {

  // console.log(`currencyCode: ${currencyCode}`);

  const maybeRounded = cost >= 10 ? Math.round(cost) : cost;

  const formatted = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(maybeRounded);

  return formatted;
}

export const localeToCurrencyCode = (locale: string) => {
  const code = locale.split("-")[1];
  const countryCode = code as TCountryCode;
  const countryData = getCountryData(countryCode);
  // console.log(`countryData: ${JSON.stringify(countryData)}`);
  
  const localeCurrencyCode = countryData.currency[0];
  return localeCurrencyCode;
}
