import React, { useEffect } from 'react';
import { Box, Container, Typography, List, ListItem, Link } from '@mui/material';
import * as metrics from "../util/metrics";

const classes = { content: "content"}

const Privacy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    metrics.reportPageLoad("Privacy", window.location.href,  window.location.pathname);
  }, [])

  return (
    <Box component="main" className={classes.content} sx={{
      flexGrow: 1,
      // p: 2,
      marginTop: 2,
      width: "100%"
    }}>
      <Container
        maxWidth='md'
      >
        <Typography component="h2" variant="h5" mt={3} mb={1}>
           Privacy Policy
        </Typography>
        {/* <Typography component="p" mt={1}>
          BillDB is ad-supported. Third party vendors, including Google, use cookies to serve ads based on a user's prior visits to this website or other websites. Google's use of advertising cookies enables it and its partners to serve ads based on your visit to this site and/or other sites on the Internet. You may opt out of personalized advertising by visiting Ads Settings.
        </Typography> */}
        <Typography component="p" mt={1}>
          BillDB uses Google Analytics, which is a popular tool that uses cookies to perform its web analytics.
        </Typography>
        <Typography component="p" mt={1}>
          <b>What does Google Analytics record?</b>
          <br />
          Google Analytics identifies what website you came from to get here, how long you stay for, what features you use or don't use, what kind of device you're using, and more. Additionally, Google Analytics may associate the visitation information it collects from this site with Google information from accounts of signed-in users who have consented to this association for the purpose of ads personalization. This Google information may include end user location, search history, YouTube history, and data from sites that partner with Google—and is used to provide aggregated and anonymized insights into users' cross device behaviors.
        </Typography>
        <Typography component="p" mt={1}>
          <b>What do we do with your data?</b>
          <br />
          The tracking information allows us to better understand our site usage patterns and user base, and that helps us make decisions about design and development.
        </Typography>
        <Typography component="p" mt={1}>
          <b>Want to opt out of tracking?</b>
          <br />
          You can opt out of Google's advertising tracking cookie or use a browser plugin to opt out of all Google Analytics tracking. You can also access and/or delete tracking data via Google's My Activity tool.
        </Typography>
      </Container>
    </Box>
  );
}

export default Privacy;