import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Container, IconButton, Modal, Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import SearchResultsTable, { SearchResultsRow } from './searchResultsTable';
import ProductAutocomplete from './productAutocomplete';
import '@algolia/autocomplete-theme-classic';
import algoliasearch from 'algoliasearch/lite';
// import ErrorComponent from "./errorComponent";
import SearchInfoModal from './searchInfoModal';
///import { Wrapper, Status } from "@googlemaps/react-wrapper";
// import MapWithMarkers from "./MapWithMarkers";
import { useSearchParams } from 'react-router-dom';
import PlaceAutocomplete, {GooglePlace} from './placeAutocomplete';
import * as metrics from "../util/metrics";
import LatestSubmissionsTable, { LatestSubmissionsRow } from './LatestSubmissionsTable';
// import { placesApiKey } from '../pages/rootPage';
import axios from 'axios';
import BusinessesTable from './businessesTable';

// import { Loader } from '@googlemaps/js-api-loader';

// const placesApiKey = "AIzaSyCDnEZTjSbioCqnkOF5Zljh6T-ugAfQ1ds";

const latestSubmissionsRows: LatestSubmissionsRow[] = [
  {
    title: "House painting cost",
    username: "seattle_researcher",
    searchQuery: "house paint",
  },
  {
    title: "Window cleaning cost",
    username: "seattle_researcher",
    searchQuery: "window",
  },
  {
    title: "Water heater replacement cost",
    username: "anonymous",
    searchQuery: "water heater",
  },
  {
    title: "Gutter cleaning cost",
    username: "seattle_researcher",
    searchQuery: "gutter",
  },
  {
    title: "Invisalign dental aligner cost",
    username: "seattle_researcher",
    searchQuery: "invisalign",
  },
  {
    title: "Pizza in Seattle",
    username: "seattle_researcher",
    searchQuery: "pizza",
  },
  {
    title: "Ski ticket prices",
    username: "aloki",
    searchQuery: "ski%20ticket",
  },
  {
    title: "Cat spay cost",
    username: "Anonymous",
    searchQuery: "cat%20spay",
  },
  {
    title: "Heat pump cost",
    username: "old_rasputin",
    searchQuery: "heat%20pump",
  },
  {
    title: "Phone screen replacement cost",
    username: "aloki",
    searchQuery: "screen",
  },
  {
    title: "Piano tuning",
    username: "nellg312",
    searchQuery: "piano",
  },
  {
    title: "Airport parking",
    username: "caba11ero",
    searchQuery: "airport%20parking",
  },
  {
    title: "MiSight myopia control",
    username: "seattle_researcher",
    searchQuery: "misight",
  },
  {
    title: "Sewer drain line",
    username: "nellg312",
    searchQuery: "sewer%20drain",
  },
  // {
  //   title: "Car wash",
  //   username: "Anonymous",
  //   searchQuery: "car%20wash",
  // },
]

const searchClient = algoliasearch(
  'U6C6TNQKO0',
  'e0462085fdf13ab65c186233db15d4a5'
);

const classes = { content: "content", toolbar: "toolbar", root: "root", details: "details", avatar: "avatar", locationText: "locationText", buttonProperty: "buttonProperty",
                  uiProgress: "uiProgress", progress: "progress", customError: "customError", submitButton: "submitButton"}

export type SearchBillsProps = {
  autocompleteService: any,
  openRewardInfoModal: () => void
}

type AlgoliaHit = {
  userId: string;
  billId: string;
  product: string;
  productNormalized: string;
  currencyCode: string;
  cost: number;
  placeNameMain: string;
  placeNameSecondary: string;
  placeId: string;
  includesTaxes: boolean,
  includesTipOrFees: boolean,
  includesCoupons: boolean,
  includesInsurance: boolean,
  isQuote: boolean,
  byBusiness: boolean,
  createdAtSecs: number;
  // notes: string;
  intlLocale: string;
  _geoloc: { lat: number, lng: number }
}

export type Business = {
  placeId: string;
  rating: number;
  totalRatings: number;
  name: string;
  _geoloc?: { lat: number, lng: number }
}

const SearchBills = ({ autocompleteService, openRewardInfoModal }: SearchBillsProps) => {

  const productParam = useSearchParams()[0].get("q");
	const navigate = useNavigate();
  const [initialProduct] = useState(productParam ?? "");
  const [product, setProduct] = useState(productParam ?? "");
  const [resultsRows, setResultsRows] = useState<SearchResultsRow[]>([]);
  const [searchInfoModalOpen, setSearchInfoModalOpen] = useState(false);
  const [placeNameMain, setPlaceNameMain] = useState("");
  const [placeNameSecondary, setPlaceNameSecondary] = useState("");
  const [selectedGooglePlace, setSelectedGooglePlace] = useState<GooglePlace>();
  const [headerPlaceName, setHeaderPlaceName] = useState("you");
  const [enteredProduct, setEnteredProduct] = useState(productParam ?? "");
  const [showSearchResults, setShowSearchResults] = useState(productParam ? true : false);
  const [businesses, setBusinesses] = useState<Business[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    metrics.reportPageLoad("Search", window.location.href,  window.location.pathname);
  }, [])

  useEffect(() => {
    const queryStringParts = [];
    if (enteredProduct) {
      queryStringParts.push("q=" + encodeURIComponent(enteredProduct));
    }
    // if (selectedGooglePlace?.placeId) {
      // queryStringParts.push("p=" + encodeURIComponent(selectedGooglePlace.placeId));
    // }
    const queryString = queryStringParts.length > 0 ? "?" + queryStringParts.join("&") : "";
    navigate(`/search${queryString}`);
  }, [enteredProduct, navigate])

  const onProductEntered = () => {
    setEnteredProduct(product);
  }

  // useCallback helps us avoid an ESLint warning when we call this in useEffect
  const performSearch = useCallback((query: string, userHitEnter: boolean) => {
    console.log(`performSearch - ${query}`);

    // const queryStringParts = [];
    // if (query) {
    //   queryStringParts.push("q=" + encodeURIComponent(query));
    // }
    // if (selectedGooglePlace?.placeId) {
    //   // queryStringParts.push("p=" + encodeURIComponent(selectedGooglePlace.placeId));
    // }
    // const queryString = queryStringParts.length > 0 ? "?" + queryStringParts.join("&") : "";
    // navigate(`/search${queryString}`); // TODO: only navigate when the product autocomplete input loses focus?

    const index = searchClient.initIndex('billdb-index-1');

    const searchParams: any = {
      attributesToRetrieve: [
        'userId', 'billId', 'product', 'productNormalized', 'currencyCode', 'cost',
        'placeNameMain', 'placeNameSecondary', 'placeId',
        'includesTaxes', 'includesTipOrFees', 'includesCoupons', 'includesInsurance', 'isQuote', 'byBusiness',
        'intlLocale', 'createdAtSecs', '_geoloc'
      ],
      hitsPerPage: 50,
      aroundRadius: 'all',
    }

    const selectedLocation = selectedGooglePlace?.geometry?.location
    if (selectedLocation) {
      searchParams.aroundLatLng = `${selectedLocation.lat()}, ${selectedLocation.lng()}`;
    } else {
      searchParams.aroundLatLngViaIP = true;
    }

    index.search<AlgoliaHit>(query, searchParams).then(({ hits }) => {
      // console.log(`performSearch - hits: ${JSON.stringify(hits)}`);
      const rows: SearchResultsRow[] = [];
      // const newLocations: google.maps.LatLngLiteral[] = [];

      if (hits.length > 0) {
        hits.forEach(hit => {
          // console.log(`performSearch - hit: ${JSON.stringify(hit)}`);
          rows.push({
            userId: hit.userId,
            billId: hit.objectID,
            product: hit.product,
            productNormalized: hit.productNormalized,
            // product: decodeURIComponent(hit.product),
            // productNormalized: decodeURIComponent(hit.productNormalized),
            currencyCode: hit.currencyCode,
            cost: hit.cost,
            placeNameMain: hit.placeNameMain,
            placeNameSecondary: hit.placeNameSecondary,
            placeId: hit.placeId,
            includesTaxes: hit.includesTaxes,
            includesTipOrFees: hit.includesTipOrFees,
            includesCoupons: hit.includesCoupons,
            includesInsurance: hit.includesInsurance,
            isQuote: hit.isQuote,
            byBusiness: hit.byBusiness,
            createdAtSecs: hit.createdAtSecs,
            // notes: hit.notes,
            intlLocale: hit.intlLocale,
            _geoloc: hit._geoloc
          });
          // newLocations.push(hit._geoloc);
        });
      } else if (userHitEnter) {
        // search Places text api
        if (query && query.length > 1) {
          const lat = selectedLocation?.lat();
          const lng = selectedLocation?.lng();

          axios.post("/api/misc/placesTextSearch", {
            query: encodeURIComponent(query),
            lat,
            lng
          })
          .then((response) => {
            // console.log("response: " + JSON.stringify(response));
            // console.log(`data.results: ${JSON.stringify(response.data.results)}`);
            const mapped: Business[] = response.data.results
            .filter((result: any) => result.business_status === "OPERATIONAL")
            .map((result: any) => ({
              placeId: result.place_id,
              rating: result.rating,
              totalRatings: result.user_ratings_total,
              name: result.name,
              _geoloc: result.geometry.location
            }));
            setBusinesses(mapped);
            // console.log(`mapped: ${JSON.stringify(mapped)}`);
          })
          .catch((error) => {
            console.error(error);
          });



          // const loc = selectedLocation ? "&location=" + selectedLocation.lat() + "%2C" + selectedLocation.lng() : "";
          // const url = "https://maps.googleapis.com/maps/api/place/textsearch/json"
          //   + `?query=${query}`
          //   + loc
          //   + (loc ? "&rankby=distance" : "")
          //   + "&type=establishment"
          //   + `&key=${placesApiKey}`;

          // fetch(url)
          // .then(response => {
          //   console.log("response: " + response.json());
          // }).catch(error => {
          //   console.log("error: " + error);
          // })

          // .then(data => this.setState({ totalReactPackages: data.total }));
        }



      }

      setProduct(query);
      setResultsRows(rows);
      setShowSearchResults(true);
      // setLocations(newLocations);
    });
  }, [setResultsRows, selectedGooglePlace]);

  useEffect(() => {
    if (product || selectedGooglePlace || showSearchResults) {
      if (product === enteredProduct) {
        // user hit the enter key, exited the input field, or changed the location
        performSearch(product, true);
      } else {
        performSearch(product, false);
      }
    }
  }, [performSearch, product, enteredProduct, selectedGooglePlace, showSearchResults])

  useEffect(() => {
    if (product !== enteredProduct) {
      setBusinesses([]);
    }
  }, [product, enteredProduct])


  // const render = (status: Status) => {
  //   if (status === Status.FAILURE) return <ErrorComponent />;
  //   return (
  //     <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
  //       <CircularProgress size={150} className={classes.uiProgress} sx={{
  //         zIndex: '100',
  //         height: '31px',
  //         width: '31px',
  //       }} />
  //     </Box>
  //   );
  // };

  const selectedLocation = selectedGooglePlace?.geometry?.location

  return (
    <Box component="main" className={classes.content} sx={{
      flexGrow: 1,
      // p: 2,
      marginTop: 1,
      width: "100%"
    }}>
      {/* <Wrapper apiKey={placesApiKey} libraries={['places']} render={render}> */}
        <Container maxWidth="md" >

          <Box display='flex' flexDirection='row' alignItems='center' mt={2} marginBottom={1}>
            <Typography component="h2" variant="h6" fontWeight={700}>
              {/* What did others pay? */}
              Search for what others paid
            </Typography>
            <IconButton
              color="primary"
              aria-label="searching help"
              sx={{marginLeft: 1}}
              onClick={() => setSearchInfoModalOpen(true)}
            >
              <InfoIcon />
            </IconButton>
            {/* <Box display='flex' justifyContent='flex-end' flex={1}>
              <Button variant="outlined" onClick={openRewardInfoModal} sx={{ml: 1}}>
                Win $100
              </Button>
            </Box> */}
            <Modal
              keepMounted
              open={searchInfoModalOpen}
              onClose={() => setSearchInfoModalOpen(false)}
              aria-labelledby="search-info-modal-title"
              aria-describedby="search-info-modal-description"
            >
              <SearchInfoModal handleClose={() => setSearchInfoModalOpen(false)}/>
            </Modal>
          </Box>

          <Box mt={3} sx={{
            position: "relative",
          }}>
            <ProductAutocomplete
              initialProduct={initialProduct}
              product={product}
              setProduct={setProduct}
              onProductEntered={onProductEntered}
            />
          </Box>
          <Box mt={3}>
            <PlaceAutocomplete
              placeNameMain={placeNameMain}
              placeNameSecondary={placeNameSecondary}
              setPlaceNameMain={newPlaceNameMain => setPlaceNameMain(newPlaceNameMain)}
              setPlaceNameSecondary={newPlaceNameSecondary => setPlaceNameSecondary(newPlaceNameSecondary)}
              selectedGooglePlace={selectedGooglePlace}
              setSelectedGooglePlace={newSelectedGooglePlace => setSelectedGooglePlace(newSelectedGooglePlace)}
              autocompleteService={autocompleteService}
              setHeaderPlaceName={setHeaderPlaceName}
            />
          </Box>
{/* 
          <Box mt={3}>
            <Typography fontWeight={700}>
              Prices paid near {headerPlaceName}
            </Typography>
          </Box> */}

          {showSearchResults &&
            <Box mt={3}>

              {resultsRows?.length > 0 &&
                <Box>
                  <Box mb={1}>
                    <Typography fontWeight={700}>
                      {resultsRows.length} result{resultsRows.length === 1 ? "" : "s"} near {headerPlaceName}
                      {/* Prices paid near {headerPlaceName} */}
                    </Typography>
                  </Box>

                  <Box mt={1}>
                    <SearchResultsTable rows={resultsRows} />
                  </Box>
                </Box>
              }

              {businesses?.length > 0 &&
                <Box>
                  <Box mb={1}>
                    <Typography fontWeight={700}>
                      No price results found
                    </Typography>
                    <Typography fontSize="0.8rem">
                      Showing nearest matching businesses
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <BusinessesTable rows={businesses} userLocation={selectedLocation}/>
                  </Box>
                </Box>
              }

              <Box display="flex" alignItems="center" flexDirection="column" mt={4}>
                <Button variant="outlined" href="/submit">
                  Submit a bill
                </Button>
                <Typography fontSize="0.8rem" sx={{mt: 1}}>
                  BillDB is powered by submissions from users like you.
                </Typography>
              </Box>

            </Box>
          }

          {!showSearchResults &&
            <Box mt={6}>
              <Typography fontWeight={700}>
                Latest Submissions
              </Typography>
              <Box sx={{
                mt: 1
              }}>
                <LatestSubmissionsTable rows={latestSubmissionsRows} />
              </Box>
            </Box>
          }

          {/* <Box sx={{
            mt: 1
          }}>
            <MapWithMarkers
              center={selectedPlace?.geometry?.location ? {lat: selectedPlace?.geometry?.location.lat(), lng: selectedPlace?.geometry?.location.lng()} : { lat: 40.749933, lng: -73.98633 }}
              zoom={5}
              locations={locations}
            />
          </Box> */}

        </Container>
      {/* </Wrapper> */}
    </Box>
  );
}

export default SearchBills;
