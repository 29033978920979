import React, { useEffect } from "react";
import { Box, Typography, Link } from "@mui/material";
import image from "../images/blog/invisalign_gold_coins.webp";
import * as metrics from "../../util/metrics";

const BlogPostInvisalign = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    metrics.reportPageLoad("Blog", window.location.href,  window.location.pathname);
  }, [])

  return (
    <Box>
      <Link href="/blog/misight_myopia_cost_saving" underline="hover">
        <Typography
          component="h2"
          variant="h5"
          mt={3}
          mb={1}
          fontSize='2rem'
          color='#242424'
        >
          How Much Should Invisalign Cost?
        </Typography>
      </Link>
      <Typography
        pb={1}
      >
        <time>November 28, 2023</time>
      </Typography>

      <Box
        mt={3}
        mb={2}
      >
        <img
          src={image}
          alt="Optometrist phoropter with dollar signs"
          loading="lazy"
          style={{
            height: "300px",
            objectFit: "cover",
            width: "100%"
          }}
        />
      </Box>

      <Typography component="p" mt={2}>
        How do you know if you're paying too much for Invisalign?
        Maybe you're paying hundreds or even thousands more than you should.
      </Typography>
      <Typography component="p" mt={2}>
        For those out of the loop, <Link href="https://www.aligntech.com/" target="_blank" rel="noopener" underline="hover">Invisalign</Link> dental aligners have become a popular way for kids and adults to straighten teeth without some of the drawbacks (most notably, aesthetic) of metal braces.
        Maybe this is one of the reasons they're expensive—the multi-billion-dollar <Link href="https://www.aligntech.com/" target="_blank" rel="noopener" underline="hover">Align Technology</Link> (makers of Invisalign) can charge a lot for their aligners given these advantages.
      </Typography>
      <Typography component="p" mt={2}>
        That said, the aligners themselves are only a portion of the cost.
        Orthodontists give other reasons the cost can be high, such as the aligner count and the complexity of your case, but there's one important one that they ignore: <b>it's hard to comparison shop for orthodontics</b>.
        It's easy to comparison shop for, say, the price of a new TV, but it's far more trouble to visit multiple orthodontists for quotes despite the much higher stakes.
        At the end of the day, if we don't comparison shop for a particular thing—driving competition among merchants—we're going to be spending more for that thing.
      </Typography>
      <Typography component="p" mt={2}>
        It doesn't have to be this way.
        We <b>can</b> create more competition.
        We <b>can</b> make it easier to compare prices.
        We just need to be able to easily see what different orthodontists charge for roughly the same treatment.
      </Typography>
      <Typography component="p" mt={2}>
        To that end, I've surveyed Invisalign wearers for some basic details and added them to BillDB.
        If you <Link href="/search?q=invisalign" target="_blank" rel="noopener">search for "invisalign"</Link>, you can see what people paid for their treatments and not only their general locations but which specific orthodontist charged that rate.
        With this data, you can start to make more informed decisions.
      </Typography>
      <Typography component="p" mt={2}>
        For example, one contributor reported being charged $4,000 in Seattle for a 3-month treatment, while another was charged $3,050 for a slightly longer treatment in Denver.
        Both orthodontists have ratings of 4.9 stars on Google, but one charged $1,000 less than the other for what is unlikely to be a complex treatment.
        Thinking of moving to Seattle?
        Maybe get that orthodontia done ahead of time.
      </Typography>
      <Typography component="p" mt={2}>
        Speaking of Seattle, another contributor reported being charged $6,390 for 12-month treatment at a Seattle orthodontist.
        While the 3-month, $4,000 treatment is much shorter and possibly less complex, it's probably worth at least getting a quote from that orthodontist (also rated 4.9 stars) if you're planning on getting Invisalign in Seattle.
      </Typography>
      <Typography component="p" mt={2}>
        While we can conclude the above from the roughly 20 Invisalign users I surveyed, this just is a very small sample of what's possible.
        The real magic will happen when more people like you <Link href="/submit" target="_blank" rel="noopener">submit</Link> their quotes.
        Imagine being able to instantly see multiple price examples from all the orthodontists in your area.
      </Typography>
      <Typography component="p" mt={2}>
        <i>If two orthodontists have the same rating, but you can see in BillDB that one has consistently charged patients hundreds of dollars more for comparable treatment, would that help you choose one vs. the other?</i>
      </Typography>

    </Box>
  );
}

export default BlogPostInvisalign;