import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { CssBaseline, Box, IconButton, useMediaQuery, Dialog, Slide, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import Account from '../components/account';
import SearchBills from '../components/searchBills';
import SubmitBill from '../components/submitBill';
import About from '../components/about';
import Dashboard from '../components/dashboard';
import Blog from '../components/blog';
import Terms from '../components/terms';
import Privacy from '../components/privacy';
import AdminDashboard from '../components/adminDashboard';
// import SignIn from '../components/signIn';
import AuthProviders from '../components/authProviders';
// import SignUp from '../components/signUp';
import PasswordResetDialog from '../components/passwordResetDialog';
import Navbar from "../components/navbar";
import Footer from '../components/footer';
import { firebaseAuth } from '../util/firebase';
import { sendPasswordResetEmail, getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { Loader } from '@googlemaps/js-api-loader';
import RewardInfoModal from '../components/rewardInfoModal';
// import { useSearchParams } from 'react-router-dom';
import { reportAction } from '../util/metrics';

export const placesApiKey = "AIzaSyCDnEZTjSbioCqnkOF5Zljh6T-ugAfQ1ds";

const loader = new Loader({
  apiKey: placesApiKey,
  version: "weekly",
  libraries: ["places", "geometry"]
});

const classes = { root: "root", appBar: "appBar", drawer: "drawer", drawerPaper: "drawerPaper", content: "content", avatar: "avatar", uiProgress: "uiProgress", toolbar: "toolbar"}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const featureToTabId = (feature: string | undefined) => {
  switch (feature) {
    case "search":
      return 0;
    case "submit":
      return 1;
    case "blog":
      return 2;
    default:
      return false;
  }
}

const validateFeatureName = (name: string | undefined) => {
  const trimmed = name?.trim().toLowerCase();

  // console.log("trimmed: " + trimmed);

  switch (trimmed) {
    case "search":
    case "submit":
    case "blog":
    case "about":
    case "terms":
    case "privacy":
    case "account":
    case "dashboard":
    case "admin":
      return trimmed;
  }

  return "search";
}

type RootPageProps = {
  feature?: string;
}

const RootPage = ({ feature = 'search' }: RootPageProps) =>{
  const [featureToRender, setFeatureToRender] = useState(validateFeatureName(feature));
  const [selectedTab, setSelectedTab] = React.useState<number | boolean>(featureToTabId(feature));
  const [submissionCurrencyCode, setSubmissionCurrencyCode] = useState("USD");
  const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState(false);
  const [autocompleteService, setAutocompleteService] = useState<any>();
  const [rewardInfoModalOpen, setRewardInfoModalOpen] = useState(false);
  // const [rewardInfoModalOpen, setRewardInfoModalOpen] = useState(useSearchParams()[0].get("showRewardInfo") !== null);
  const [authCurrentUser, setAuthCurrentUser] = useState<User | undefined>();
  const [isBusiness, setIsBusiness] = useState(false);

  const [forceSearchRemount, setForceSearchRemount] = useState(1);
  const [forceBlogRemount, setForceBlogRemount] = useState(1);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthCurrentUser(user);
      } else {
        setAuthCurrentUser(undefined);
      }
    });
  });

  useEffect(() => {
    loader.importLibrary('places').then(() => {
      if (!autocompleteService && (window as any).google) {
        setAutocompleteService(new (
          window as any
        ).google.maps.places.AutocompleteService());
      }
    })
  }, [autocompleteService])

  const navigate = useNavigate();

  const loadSearchPage = (query?: string, place?: string) => {
    if (query && place) {
      navigate(`/search?q=${query}&p=${place}`);
    } else {
      navigate("/search");
    }
		setFeatureToRender("search");
    setSelectedTab(featureToTabId("search"));
	};

  const loadSubmitPage = () => {
    navigate("/submit");
		setFeatureToRender("submit")
    setSelectedTab(featureToTabId("submit"));
	};

  const loadBlogPage = () => {
    navigate("/blog");
		setFeatureToRender("blog")
    setSelectedTab(featureToTabId("blog"));
	};

  const loadTermsPage = () => {
    navigate("/terms");
		setFeatureToRender("terms")
    setSelectedTab(featureToTabId("terms"));
	};

  const loadPrivacyPage = () => {
    navigate("/privacy");
		setFeatureToRender("privacy")
    setSelectedTab(featureToTabId("privacy"));
	};

	const loadAccountPage = () => {
    navigate("/account");
		setFeatureToRender("account")
    setSelectedTab(featureToTabId("account"));
	};

  const loadAboutPage = () => {
    navigate("/about");
		setFeatureToRender("about")
    setSelectedTab(featureToTabId("about"));
	};

  const loadDashboardPage = () => {
    navigate("/dashboard");
		setFeatureToRender("dashboard")
    setSelectedTab(featureToTabId("dashboard"));
	};

  const loadAdminDashboardPage = () => {
    navigate("/admin");
		setFeatureToRender("admin")
	};

  const loadSignInPage = () => {
    handleClickOpen(false);
	};

  const loadSignUpPage = () => {
    handleClickOpen(true);
	};

	const signOutHandler = () => {
    const auth = getAuth();
    auth.signOut();
		loadSearchPage();
    enqueueSnackbar('Signed out.', { variant: 'success' });
    reportAction("sign_out", "engagement");
	};

  const navAction = (action: string) => {
    switch (action) {
      case "home":
      case "search":
        setForceSearchRemount(forceSearchRemount + 1);
        loadSearchPage();
        break;
      case "submit":
        loadSubmitPage();
        break;
      case "sign in":
        loadSignInPage();
        break;
      case "create account":
        loadSignUpPage();
        break;
      case "sign out":
        signOutHandler();
        break;
      case "about":
        loadAboutPage();
        break;
      case "blog":
        setForceBlogRemount(forceBlogRemount + 1);
        loadBlogPage();
        break;
      case "terms":
        loadTermsPage();
        break;
      case "privacy":
        loadPrivacyPage();
        break;
      case "dashboard":
        loadDashboardPage();
        break;
      case "admin":
        loadAdminDashboardPage();
        break;
      case "account":
        loadAccountPage();
        break;
    }
  }

  const [authOpen, setAuthOpen] = React.useState(false);
  const [signUpMode, setSignUpMode] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = (signUp: boolean) => {
    setSignUpMode(signUp);
    setAuthOpen(true);
  };

  const handleCloseAuth = () => {
    // console.log("handleCloseAuth");
    setAuthOpen(false);
    switch (featureToRender) {
      case 'submit':
      case 'account':
      case 'dashboard':
        loadSearchPage();
        break;
    }
  };

  const showSignIn = () => {
    if (!authOpen) {
      handleClickOpen(false);
    }
  }

  const showSignUp = () => {
    if (!authOpen) {
      handleClickOpen(true);
    }
  }

  const openRewardInfoModal = () => {
    // setRewardInfoModalOpen(true);
  }

  return (
    <Box className={classes.root} sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    }}>
      <SnackbarProvider maxSnack={1} />


      <Dialog
        fullScreen={fullScreen}
        open={authOpen}
        TransitionComponent={Transition}
        onClose={handleCloseAuth}
        aria-labelledby="responsive-dialog-title"
      >

        <Box
          display="flex"
          justifyContent={"flex-end"}
          p={2}
        >
          <IconButton
            onClick={() => handleCloseAuth()}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {signUpMode &&
          <AuthProviders
            signInComplete={() => {
              setAuthOpen(false);
              enqueueSnackbar('Signed in.', { variant: 'success' });
            }}
            showSignUp={true}
            showPasswordReset={() => {
              setAuthOpen(false);
              setPasswordResetDialogOpen(true);
            }}
            />
        }

        {!signUpMode &&
          <AuthProviders
            signInComplete={() => {
              setAuthOpen(false);
              enqueueSnackbar('Signed in.', { variant: 'success' });
            }}
            showSignUp={false}
            showPasswordReset={() => {
              setAuthOpen(false);
              setPasswordResetDialogOpen(true);
            }}
          />
        }
      </Dialog>

      <Dialog open={passwordResetDialogOpen}>
        <PasswordResetDialog
          handleSubmittedEmailAddress={emailAddress => {
            sendPasswordResetEmail(firebaseAuth, emailAddress, {
              url: "https://billdb.com"
            })
            .then(() => {
              setPasswordResetDialogOpen(false);
              enqueueSnackbar('Request submitted', { variant: 'success' });
            })
            .catch((errorResponse) => {
              setPasswordResetDialogOpen(false);
              // console.log(`errorResponse: ${errorResponse}`);
              // console.log(errorResponse?.response?.data?.error);
              // if (errorResponse?.response?.data?.error === "auth/invalid-email") {
              //   enqueueSnackbar('Invalid email address', { variant: 'error' });
              // } else {
                enqueueSnackbar('An error occurred', { variant: 'error' });
              // }
            });
          }}
          handleCancel={() => setPasswordResetDialogOpen(false)}
        />
      </Dialog>

      {/* <Modal
        keepMounted
        open={rewardInfoModalOpen}
        onClose={() => setRewardInfoModalOpen(false)}
        aria-labelledby="reward-info-modal-title"
        aria-describedby="reward-info-modal-description"
      >
        <RewardInfoModal handleClose={() => setRewardInfoModalOpen(false)}/>
      </Modal> */}

      <Navbar isSignedIn={authCurrentUser !== undefined} navCallback={navAction} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
      <CssBaseline />
      <Box sx={{
        marginTop: "68px",
        display: "flex",
        flex: 1,
        backgroundColor: '#fff',
        pb: 6,
      }}>{
        featureToRender === "account" ?
          <Account
            submissionCurrencyCode={submissionCurrencyCode}
            setSubmissionCurrencyCode={setSubmissionCurrencyCode}
            showSignIn={showSignIn}
            authCurrentUser={authCurrentUser}
          /> :
        featureToRender === "search" ?
          <SearchBills
            autocompleteService={autocompleteService}
            openRewardInfoModal={openRewardInfoModal}
            key={forceSearchRemount}
          />:
        featureToRender === "about" ?
          <About /> :
        featureToRender === "blog" ?
          <Blog
            openRewardInfoModal={openRewardInfoModal}
            key={forceBlogRemount}
          /> :
        featureToRender === "terms" ?
          <Terms /> :
        featureToRender === "privacy" ?
          <Privacy /> :
        featureToRender === "admin" ?
          <AdminDashboard
            showSignIn={showSignIn}
            authCurrentUser={authCurrentUser}
          /> :
        featureToRender === "dashboard" ?
          <Dashboard
            showSignIn={showSignIn}
            authCurrentUser={authCurrentUser}
          /> :
        <SubmitBill
          submissionCurrencyCode={submissionCurrencyCode}
          setSubmissionCurrencyCode={setSubmissionCurrencyCode}
          showSignIn={() => handleClickOpen(false)}
          showSignUp={showSignUp}
          autocompleteService={autocompleteService}
          openRewardInfoModal={openRewardInfoModal}
          authCurrentUser={authCurrentUser}
          isBusiness={isBusiness}
          setIsBusiness={setIsBusiness}
        />
      }
      </Box>
      <Footer />
    </Box>
  );
}

export default RootPage;
