import React, { useEffect } from "react";
import { Box, Typography, Link } from "@mui/material";
import billdbMapImg from "../images/blog/billdb_map.webp";
import * as metrics from "../../util/metrics";

const BlogPostMission = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    metrics.reportPageLoad("Blog", window.location.href,  window.location.pathname);
  }, [])

  return (
    <Box>
      <Link href="/blog/billdb_mission" underline="hover">
        <Typography
          component="h2"
          variant="h5"
          mt={3}
          mb={1}
          fontSize='2rem'
          color='#242424'
        >
          The BillDB Mission
        </Typography>
      </Link>
      <Typography
        pb={1}
      >
        <time>October 26, 2023</time>
      </Typography>

      <Box
        mt={3}
        mb={2}
      >
        <img
          src={billdbMapImg}
          alt="Map with dollar sign pins"
          loading="lazy"
          style={{
            height: "300px",
            objectFit: "cover",
            width: "100%"
          }}
        />
      </Box>

      <Typography component="p" mt={1}>
        Car prices are easy to find online, but what are people actually paying after dealer fees, discounts, and negotiation?
      </Typography>
      <Typography component="p" mt={2}>
        Which grocery store has the best prices in your area? What about your<i> whole country?</i>
      </Typography>
      <Typography component="p" mt={2}>
        Need a plumber? Roofer? Car mechanic?
        Reviews, ratings, and estimates are useful, but wouldn't it be great to instantly know how much these businesses actually charge their customers?
      </Typography>
      <Typography component="p" mt={2}>
        Imagine seeing what each local dentist has been charging for a given routine procedure.
        Let's take it further: Imagine U.S. healthcare providers' billing practices being exposed and those providers ultimately <i>competing with each other</i> to offer lower costs.
      </Typography>
      <Typography component="p" mt={2}>
        This is possible — perhaps <i>only</i> possible — with data that we have never before had.
      </Typography>
      <Typography component="p" mt={2}>
        So, how do we all get that data?
        We get it the same way we get merchant ratings, product reviews, and even salary data — we, the community, share it.
      </Typography>
      <Typography component="p" mt={2}>
        Posting what we are paid used to be taboo, but now that we do it we're far more empowered job-seekers.
        Let's do the same with <i>what we pay.</i> Let's share with each other what we've been paying so we can easily make informed decisions.
        We'll expose opaque markets such as healthcare, and we'll make it easy to see who provides the best deal for just about anything.
      </Typography>
      <Typography component="p" mt={2}>
        Hence BillDB's mission: Expose pricing everywhere so that we can make more informed decisions and enjoy a more competitive marketplace.
      </Typography>
    </Box>
  );
}

export default BlogPostMission;