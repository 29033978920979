import React, { useState, useEffect } from "react"
import { Box, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, Input, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { reportAction } from "../util/metrics";

export type ChangePasswordDialogProps = {
  handleSendChangePasswordEmail: () => void,
  handleCancel: () => void
}

const ChangePasswordDialog = ({ handleSendChangePasswordEmail, handleCancel }: ChangePasswordDialogProps) => {
  const [selectedEmail, setSelectedEmail] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    reportAction("show_change_password_dialog", "engagement");
  }, []);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value.trim();

    if (email.length > 0) {
      // if (username.length < 3 || username.length > 12) {
      //   setError("Must be 3-12 characters long");
      //   return;
      // }

      // if (username.includes(" ")) {
      //   setError("May not contain spaces");
      //   return;
      // }

      // if (!username.match(/^[\p{Letter}\p{Nd} _.-]+$/u)) {
      //   setError("Must only consist of letters/numbers");
      //   return;
      // }

      // if (!username.match(/^\p{Letter}/u)) {
      //   setError("Must start with at least one letter");
      //   return;
      // }
    }

    setError("");
    setSelectedEmail(email);
  }

  return (
    <Box>
      <DialogTitle>
        Password Change
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          To change your password, follow the link in the password reset email that will be sent to your email address.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button disabled={error !== ""} onClick={() => handleSendChangePasswordEmail()}>Send Reset Link</Button>
      </DialogActions>
    </Box>
  );
}

export default ChangePasswordDialog;