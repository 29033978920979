import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import RootPage from './pages/rootPage';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			light: '#33c9dc',
			// main: '#FF5722',
			// dark: '#d50000',
			main: '#3e9c35',
			dark: '#036704',
			contrastText: '#fff'
		},
    white: {
      light: '#fff',
			main: '#fff',
			dark: '#fff',
			contrastText: '#fff'
    }
  },
  components: {
    // Remove up/down buttons from number input TextField
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
  },
});

const App = () => {

  // console.log("window.location.href: " + window.location.href);

	return (
		<ThemeProvider theme={theme}>
			<Router>
        <Routes>
          <Route path="/" element={ <RootPage />} />
          <Route path="/search" element={ <RootPage feature='search' />} />
          <Route path="/submit" element={ <RootPage feature='submit' />} />
          <Route path="/blog" element={ <RootPage feature='blog' />} />
          <Route path="/blog/*" element={ <RootPage feature='blog' />} />
          <Route path="/about" element={ <RootPage feature='about' />} />
          <Route path="/terms" element={ <RootPage feature='terms' />} />
          <Route path="/privacy" element={ <RootPage feature='privacy' />} />
          <Route path="/account" element={ <RootPage feature='account' />} />
          <Route path="/dashboard" element={ <RootPage feature='dashboard' />} />
          <Route path="/admin" element={ <RootPage feature='admin' />} />
          <Route path="*" element={ <Navigate to="/" replace /> } />
        </Routes>
			</Router>
		</ThemeProvider>
	);
}

export default App;
