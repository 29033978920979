import React, { useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { reportAction } from '../util/metrics';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxWidth: "100%",
  maxHeight: "100%",
  overflowY: "scroll"
};

export type SubmitInfoModalProps = {
  handleClose: () => void
}

// const SubmitInfoModal = ({ handleClose }: SubmitInfoModalProps) => {
const SubmitInfoModal = React.forwardRef(({ handleClose }: SubmitInfoModalProps, ref) => {

  useEffect(() => {
    reportAction("show_submit_info_dialog", "engagement");
  }, []);

  return (
    <Box sx={style}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6" component="h2" id="submit-info-modal-title">
          What were you charged for something?
        </Typography>
        <Box>
          <IconButton
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Typography variant="body2" mt={2} component="div">
        Tips for sharing what you were billed:
      </Typography>
      <List dense={true} sx={{ listStyleType: 'disc', pl: 2 }}>
        <ListItem sx={{ display: 'list-item', pl: 0 }}>
            You can post either as a customer or as the service provider (business).
          </ListItem>
          <ListItem sx={{ display: 'list-item', pl: 0 }}>
            Bills should be recent (within the last 30 days).
          </ListItem>
          <ListItem sx={{ display: 'list-item', pl: 0 }}>
            Try to keep the product/service description to just a few words, e.g., "dental crown".
          </ListItem>
          <ListItem sx={{ display: 'list-item', pl: 0 }}>
            To change the currency, tap the currency symbol.
          </ListItem>
          <ListItem sx={{ display: 'list-item', pl: 0 }}>
            If you paid a tip or other fees, consider including them in the cost to show the total out-of-pocket cost.
          </ListItem>
        </List>
    </Box>
  );
});

export default SubmitInfoModal;
