import React, { useEffect } from "react"
import { Box, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from "@mui/material";
import { reportAction } from "../util/metrics";

export type DeleteAccountDialogProps = {
  handleDeleteAccount: () => void,
  handleCancel: () => void
}

const DeleteAccountDialog = ({ handleDeleteAccount, handleCancel }: DeleteAccountDialogProps) => {
  useEffect(() => {
    reportAction("show_delete_account_dialog", "engagement");
  }, []);

  return (
    <Box>
      <DialogTitle>
        Delete Account
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete your account? This will also delete all your submissions.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={() => handleDeleteAccount()}>Delete Account</Button>
      </DialogActions>
    </Box>
  );
}

export default DeleteAccountDialog;