import React, { useEffect } from "react";
import { Box, Typography, Link, List, ListItem } from "@mui/material";
import image from "../images/blog/pizza_chefs.webp";
import * as metrics from "../../util/metrics";

const BlogPostPizza = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    metrics.reportPageLoad("Blog", window.location.href,  window.location.pathname);
  }, [])

  return (
    <Box>
      <Link href="/blog/seattle_pizza_prices" underline="hover">
        <Typography
          component="h2"
          variant="h5"
          mt={3}
          mb={1}
          fontSize='2rem'
          color='#242424'
        >
          What Does a Pizza Cost Across Seattle?
        </Typography>
      </Link>
      <Typography
        pb={1}
      >
        <time>November 14, 2023</time>
      </Typography>

      <Box
        mt={3}
        mb={2}
      >
        <img
          src={image}
          alt="Optometrist phoropter with dollar signs"
          loading="lazy"
          style={{
            height: "300px",
            objectFit: "cover",
            width: "100%"
          }}
        />
      </Box>

      <Typography component="p" mt={2}>
        Like everything else, even prices for pizza have been getting out of hand around here.
        This has made me think twice about ordering out despite my family's Pizza and Movie Night tradition.
        That said, my efforts at homemade pizza haven't been on par with some of our favorite local options, so we continue to get takeout.
      </Typography>
      <Typography component="p" mt={2}>
        Fortunately, there's a saving grace: you can still get a nice, reasonably priced pizza if you know where to look.
      </Typography>
      <Typography component="p" mt={2}>
        Where are these deals?
        Well, it's not super simple—something that's a good deal for you will depend on price, proximity to you, and your own taste buds.
        I decided to tackle the first two: price and proximity.
      </Typography>
      <Typography component="p" mt={2}>
        To that end, I researched the price of a cheese pizza—as a benchmark—across all pizza restaurants I could find in Seattle.
        I submitted them all (more than 100!) to BillDB, so if you <Link href="/search?q=pizza" target="_blank" rel="noopener">search for pizza</Link>, you'll see everything near you sorted by distance (you might need to enter a location).
      </Typography>
      <Typography component="p" mt={2}>
        No two pizzas are exactly alike in diameter, thickness, the quality of ingredients, etc., but the numbers give you a sense of how prices compare.
        For example, Zeeks and Pagliacci come in at <b>$25</b> and <b>$26</b>, respectively, for a 17" pizza.
        Hot Mama's, which is <Link href="https://www.reddit.com/search/?q=zeek%20hot%20mama%20pizza&type=comment&sort=new" underline="hover" target="_blank" rel="noopener">often preferred</Link>, will set you back just <b>$19 for a larger pizza</b>.
      </Typography>
      <Typography component="p" mt={2}>
        I discovered some other interesting things:
      </Typography>
      <List dense={true} sx={{ listStyleType: 'disc', pl: 2 }}>
        <ListItem sx={{ display: 'list-item', pl: 0 }}>
            Like other Seattleites, I know about the high prices otherwise known as the "Amazon tax" in South Lake Union (SLU).
            I didn't expect, however, just how high that is, at least for Ballard Pizza Company.
            You'll pay a whopping <b>$10 more</b> ($36) for a cheese pizza in the SLU location vs. further north in Ballard or Fremont.
        </ListItem>
        <ListItem sx={{ display: 'list-item', pl: 0 }}>
          Who offers the cheapest whole pizza?
          Costco crushes it with $9.95 for an 18" pizza.
          Little Caesar's offers a 12" for just $9.
          That said, you might want to consider MOD if you're going to load up on toppings, as their 11" is a flat $11.59, inclusive of toppings.
        </ListItem>
        <ListItem sx={{ display: 'list-item', pl: 0 }}>
          Google shows nearly all pizza restaurants as having a cost rating of two dollar signs ($$).
          Elemental, however, gets just one ($) despite charging $18 for a 12" pizza.
          It doesn't seem accurate to bucket Elemental with Little Caesar's ($9), at least for pizza.
        </ListItem>
        <ListItem sx={{ display: 'list-item', pl: 0 }}>
          Who offers the most expensive pizza?
          If you want a whole pizza at Talarico's, you'll have to spend <b>$41.99</b>.
          That seems like a lot until you consider the cost per square inch.
          It turns out to be $0.068 per square inch, which is actually the <b>cheapest</b> among all the pizzas in the sample, aside from Costco.
        </ListItem>
        <ListItem sx={{ display: 'list-item', pl: 0 }}>
          Other low price per square inch places, all of which are <b>cheaper per square inch than Little Caesar's and are rated higher</b>:
        </ListItem>
        <List dense={true} disablePadding sx={{ listStyleType: 'disc', pl: 4 }}>
          <ListItem sx={{ display: 'list-item', pl: 0 }}>
            Hot Mama's ($0.073)
          </ListItem>
          <ListItem sx={{ display: 'list-item', pl: 0 }}>
            Bambino's Pizzeria ($0.074)
          </ListItem>
          <ListItem sx={{ display: 'list-item', pl: 0 }}>
            Ian's ($0.075)
          </ListItem>
        </List>
      </List>
      <Typography component="p" mt={2}>
        <i>
          This was a fun research project, and hopefully it saves a few folks a little money.
          Feel free, of course, to <Link href="/submit" underline="hover" target="_blank" rel="noopener">submit</Link> some prices of your own, anywhere in the world.
          Please especially consider posting prices or quotes for things that are more apples-to-apples, like how much you were billed for a checkup at the vet, plumbing service, or anything else where it's hard to price-compare.
        </i>
      </Typography>
    </Box>
  );
}

export default BlogPostPizza;