import React, { useState, useEffect } from "react"
import { Box, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, Input, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { reportAction } from "../util/metrics";

export type PasswordResetDialogProps = {
  handleSubmittedEmailAddress: (emailAddress: string) => void,
  handleCancel: () => void
}

const PasswordResetDialog = ({ handleSubmittedEmailAddress, handleCancel }: PasswordResetDialogProps) => {
  const [selectedEmail, setSelectedEmail] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    reportAction("show_password_reset_dialog", "engagement");
  }, []);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value.trim();

    if (email.length > 0) {
      // if (username.length < 3 || username.length > 12) {
      //   setError("Must be 3-12 characters long");
      //   return;
      // }

      // if (username.includes(" ")) {
      //   setError("May not contain spaces");
      //   return;
      // }

      // if (!username.match(/^[\p{Letter}\p{Nd} _.-]+$/u)) {
      //   setError("Must only consist of letters/numbers");
      //   return;
      // }

      // if (!username.match(/^\p{Letter}/u)) {
      //   setError("Must start with at least one letter");
      //   return;
      // }
    }

    setError("");
    setSelectedEmail(email);
  }

  return (
    <Box>
      <DialogTitle>
        Password Reset
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the email address associated with your account.
          If an account exists for that email address, you will receive a password reset link.
        </DialogContentText>
        <FormControl error={error !== ""} variant="standard" sx={{
          mt: 2
        }}>
          <InputLabel htmlFor="component-error">Email Address</InputLabel>
          <Input
            id="email-input"
            aria-describedby="email input"
            onChange={handleOnChange}
            type='email'
          />
          {error &&
            <FormHelperText id="component-error-text">{error}</FormHelperText>
          }
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button disabled={error !== ""} onClick={() => handleSubmittedEmailAddress(selectedEmail)}>Send Reset Link</Button>
      </DialogActions>
    </Box>
  );
}

export default PasswordResetDialog;