import React, { useState, useEffect, useCallback } from 'react';
import { useMediaQuery, useTheme, Link, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import TablePagination from '@mui/material/TablePagination';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { IntlProvider } from 'react-intl';

import { htmlDecode } from '../util/inputValidation';

import axios from 'axios';

import { formatCost } from '../util/currencyUtils';

import { Person } from '@mui/icons-material';

export type LatestSubmissionsRow = {
  title: string;
  username: string;
  searchQuery: string;
}

type RowProps = {
  row: LatestSubmissionsRow,
}


const Row = (props: RowProps) => {
  const { row } = props;

  return (
    <ListItem>
      <Box>
        <Typography>
          <Link href={`/search?q=${row.searchQuery}`} underline="hover">
            {row.title}
          </Link>
        </Typography>
        <Box display='flex' flexDirection='row' alignItems='center' marginBottom={1}>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Person sx={{ fontSize: "0.8rem", color: "#777" }} />
            <Typography fontSize='0.8rem' color="#777">{row.username}</Typography>
          </Stack>
        </Box>
      </Box>
    </ListItem>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    title: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
};

export type LatestSubmissionsTableProps = {
  rows: LatestSubmissionsRow[];
}

const LatestSubmissionsTable: React.FunctionComponent<LatestSubmissionsTableProps> = ({ rows }): JSX.Element => {
  const page = 0;
  const rowsPerPage = 10;
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const visibleRows = React.useMemo(
    () =>
      rows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [rows, page, rowsPerPage],
  );

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <List>
        {visibleRows.map((row) => (
          <Row key={row.title} row={row} />
        ))}
      </List>
    </Box>
  );
}

export default LatestSubmissionsTable;
