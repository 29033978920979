import React from "react";

import { Box, Typography, Link } from "@mui/material";

const Footer = () => {
  return (
    <Box
      display='flex'
      justifyContent='center'
      sx={{
        pt: 2,
        pl: 3,
        pr: 3,
        pb: 2,
        backgroundColor: "#f5f5f5",
        fontSize: "0.85em"
      }}
    >
      ©2023 Verdant Labs
      <Box pl={1} pr={1}>
        ·
      </Box>
      <Link underline="hover" href="/terms">Terms</Link>
      <Box pl={1} pr={1}>
        ·
      </Box>
      <Link underline="hover" href="/privacy">Privacy</Link>
      <Box pl={1} pr={1}>
        ·
      </Box>
      <Link underline="hover" href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#99;&#111;&#110;&#116;&#97;&#99;&#116;&#64;&#98;&#105;&#108;&#108;&#100;&#98;&#46;&#99;&#111;&#109;">Contact</Link>
    </Box>
  );
}

export default Footer;
