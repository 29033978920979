import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// import { Typography, Button } from '@mui/material';
import axios from 'axios';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { getOrRefreshIdToken } from '../util/auth';
import { User } from 'firebase/auth';
import * as metrics from "../util/metrics";
import { Button } from '@mui/material';

const classes = { content: "content"}


export type AdminDashboardProps = {
  showSignIn: () => void,
  authCurrentUser: User | undefined,
}

function AdminDashboard ({ showSignIn, authCurrentUser }: AdminDashboardProps) {

  useEffect(() => {
    window.scrollTo(0, 0);
    metrics.reportPageLoad("Dashboard", window.location.href,  window.location.pathname);
  }, [])

	useEffect ( () => {
    if (!authCurrentUser) {
      // showSignIn();
      return;
    }

    getOrRefreshIdToken(authCurrentUser).then(authToken => {
      if (authToken) {
      } else {
        showSignIn();
      }
    });
	}, [showSignIn, authCurrentUser]);

  const remediate = async () => {
    if (!authCurrentUser) {
      showSignIn();
      return;
    }

    const authToken = await getOrRefreshIdToken(authCurrentUser);
    if (!authToken) {
      showSignIn();
      return;
    }

    try {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const response = await axios.get('/api/admin/remediate');
      console.log(`response: ${JSON.stringify(response)}`);
      enqueueSnackbar("Done", {variant: "success"});
    } catch (err) {
      console.log(`err: ${JSON.stringify(err)}`);
      enqueueSnackbar("Failed", {variant: "error"});
    }

    // if (response.data?.numDeleted) {
    //   enqueueSnackbar(`Deleted ${response.data.numDeleted} ${response.data.numDeleted === 1 ? 'bill' : 'bills'}`, { variant: 'success' });
    //   setForceRender(forceRender + 1);
    // } else {
    //   enqueueSnackbar(`Failed to delete`, { variant: 'error' });
    //   setForceRender(forceRender + 1);
    // }
  }

  return (
    <Container component="main" className={classes.content} sx={{
      flexGrow: 1,
      // p: 2,
      marginTop: 2,
      width: "100%"
    }}>
      <SnackbarProvider maxSnack={1} />
      <Container maxWidth="md" >
        {/* <Typography component="h2" variant="h5" mt={3} mb={1}>
          Your Submissions
        </Typography> */}
        <Box mt={2}>
          <Button variant="contained" onClick={remediate}>Remediate</Button>
        </Box>
        {/* <Button variant="outlined" sx={{
          mt: 2
        }}>
          Delete Selected
        </Button> */}
      </Container>
    </Container>
  );
}

export default AdminDashboard;