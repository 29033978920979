import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// import { Typography, Button } from '@mui/material';
import DashboardTable, { Bill } from './dashboardTable';
import axios from 'axios';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { getOrRefreshIdToken } from '../util/auth';
import { User } from 'firebase/auth';
import * as metrics from "../util/metrics";

const classes = { content: "content"}


export type DashboardProps = {
  showSignIn: () => void,
  authCurrentUser: User | undefined, 
}

function Dashboard ({ showSignIn, authCurrentUser }: DashboardProps) {
  const [bills, setBills] = useState<Bill[]>([]);
  const [forceRender, setForceRender] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    metrics.reportPageLoad("Dashboard", window.location.href,  window.location.pathname);
  }, [])

	useEffect ( () => {
    if (!authCurrentUser) {
      return;
    }

    getOrRefreshIdToken(authCurrentUser).then(authToken => {
      if (authToken) {
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        axios.get('/api/bills')
          .then((response) => {
            // console.log("response: " + JSON.stringify(response));

            const newBills: Bill[] = response.data.map((elem: any) => (
              {
                id: elem.id,
                createdAtSecs: elem.createdAtSecs,
                cost: elem.cost,
                currencyCode: elem.currencyCode,
                product: elem.product,
                placeNameMain: elem.placeNameMain,
                placeNameSecondary: elem.placeNameSecondary,
                tax: elem.includesTaxes,
                tips: elem.includesTipOrFees,
                coupons: elem.includesCoupons,
                insurance: elem.includesInsurance,
                quote: elem.isQuote,
                byBusiness: elem.byBusiness,
              }
            ));

            setBills(newBills);
          })
          .catch((error) => {
            if (error.response?.status === 403) {
              showSignIn();
            }

            console.error(error);
            // setErrorMsg('Error in retrieving the data')
          });
      } else {
        showSignIn();
      }
    });
	}, [showSignIn, forceRender, authCurrentUser]);

  const deleteBills = async (billIds: readonly string[]) => {
    const response = await axios.post('/api/bills/delete', {
      billIds
    });
    // console.log(`response: ${JSON.stringify(response)}`);
    if (response.data?.numDeleted) {
      enqueueSnackbar(`Deleted ${response.data.numDeleted} ${response.data.numDeleted === 1 ? 'bill' : 'bills'}`, { variant: 'success' });
      setForceRender(forceRender + 1);
    } else {
      enqueueSnackbar(`Failed to delete`, { variant: 'error' });
      setForceRender(forceRender + 1);
    }
  }

  return (
    <Container component="main" className={classes.content} sx={{
      flexGrow: 1,
      // p: 2,
      marginTop: 2,
      width: "100%"
    }}>
      <SnackbarProvider maxSnack={1} />
      <Container maxWidth="md" >
        {/* <Typography component="h2" variant="h5" mt={3} mb={1}>
          Your Submissions
        </Typography> */}
        <Box mt={2}>
          <DashboardTable bills={bills} deleteBills={deleteBills}/>
        </Box>
        {/* <Button variant="outlined" sx={{
          mt: 2
        }}>
          Delete Selected
        </Button> */}
      </Container>
    </Container>
  );
}

export default Dashboard;