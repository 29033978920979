import React, { useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TablePagination from '@mui/material/TablePagination';
import { Business } from './searchBills';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

// import IntlMessageFormat from 'intl-messageformat';

type RowProps = {
  row: Business,
  userLocation?: google.maps.LatLng,
  useImperialUnits?: boolean
}

const locale = Intl.NumberFormat().resolvedOptions().locale;

const Row = (props: RowProps) => {
  const { row, userLocation, useImperialUnits } = props;
  const [open, setOpen] = React.useState(false);
  const mapUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyCDnEZTjSbioCqnkOF5Zljh6T-ugAfQ1ds
  &q=place_id:${row.placeId}&zoom=10`;

  const expandRow = async () => {
    setOpen(!open);
  }

  // const fontFamily = "monospace";
  const fontFamily = "inherit";
  const fontSize = "0.9rem";
  const fontSizeSmall = "0.7rem";
  const colorLight = "#777";

  let distance;
  if (userLocation && row._geoloc) {
    const distanceMeters = google.maps.geometry.spherical.computeDistanceBetween(userLocation, row._geoloc);
    if (useImperialUnits) {
      distance = "" + Math.round(distanceMeters / 160.9344) / 10 + "mi";
    } else {
      distance = "" + Math.round(distanceMeters / 100) / 10 + "km";
    }
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ p: 1 }}>
          <Typography fontSize={fontSize} fontFamily={fontFamily}>{row.name}</Typography>
          {distance &&
            <Typography fontSize={fontSizeSmall} color={colorLight} fontFamily={fontFamily}>{distance}</Typography>
          }
        </TableCell>
        <TableCell sx={{ p: 1, minWidth: "80px" }}>
          {row.rating > 0 &&
            <Box>
              <Box display="inline-flex" alignItems="center">
                <Typography fontSize={fontSize} fontFamily={fontFamily} sx={{ mr: "0.1rem" }}>{row.rating.toFixed(1)}</Typography>
                {[...Array(5)].map((x, i) => {
                  if (row.rating - i >= 0.75) {
                    return <StarIcon color="secondary" sx={{ fontSize: "0.8rem", mr: "-0.15rem" }}/>
                  } else if (row.rating - i >= 0.25) {
                    return <StarHalfIcon color="secondary" sx={{ fontSize: "0.8rem", mr: "-0.15rem" }}/>
                  } else {
                    return <StarOutlineIcon color="secondary" sx={{ fontSize: "0.8rem", mr: "-0.15rem" }}/>
                  }
                })}
              </Box>
              <Typography fontSize={fontSizeSmall} color={colorLight} fontFamily={fontFamily}>{row.totalRatings} review{row.totalRatings === 1 ? "" : "s"}</Typography>
            </Box>
          }
        </TableCell>
        <TableCell align="right" sx={{ pr: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => expandRow()}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Box
                component="iframe"
                allowFullScreen
                height="300px"
                width="100%"
                loading="lazy"
                title="business location map"
                border="none"
                // referrerpolicy="no-referrer-when-downgrade"
                src={mapUrl}
                >
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export type BusinessesTableProps = {
  rows: Business[];
  userLocation?: google.maps.LatLng;
}

const BusinessesTable: React.FunctionComponent<BusinessesTableProps> = ({ rows, userLocation }): JSX.Element => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const localeCountry = locale.split("-")[1];
  const useImperialUnits = (localeCountry === "US") || (localeCountry === "GB") || (localeCountry === "LR") || (localeCountry === "MM");

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      rows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [rows, page, rowsPerPage],
  );

  const theme = useTheme();

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ width: '100%', mb: 2 }}>
        <TableContainer>

          <Table aria-label="collapsible table">
            <TableBody>
              {visibleRows.map((row) => (
                <Row key={row.placeId} row={row} userLocation={userLocation} useImperialUnits={useImperialUnits}/>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>

        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={useMediaQuery(theme.breakpoints.up('sm')) ? [5, 10, 25]: []}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
}

export default BusinessesTable;
