import React, { useEffect } from 'react';
import { Box, Container, Typography, List, ListItem, Link } from '@mui/material';
import * as metrics from "../util/metrics";

const classes = { content: "content"}

const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    metrics.reportPageLoad("About", window.location.href, window.location.pathname);
  }, [])

  return (
    <Box component="main" className={classes.content} sx={{
      flexGrow: 1,
      p: 2,
      width: "100%"
    }}>
      <Container
        maxWidth='md'
      >
        <Typography component="h2" variant="h5" mt={3} mb={1}>
           What is BillDB?
        </Typography>
        <Typography component="p" mt={1}>
          It's a searchable, community-sourced repository of prices for products and services.
          You can find ratings and reviews for a given business online, but the picture isn't complete until you have a good sense of what they charge vs. their competitors.
          In some cases, the pricing might even be more essential than reviews.
        </Typography>
        <Typography component="h2" variant="h5" mt={3} mb={1}>
           What kinds of things can I search for?
        </Typography>
        <Typography component="p" mt={1}>
          What's available depends on what data the community has shared.
          That said, here are some ideas:
        </Typography>
        <List dense={true} sx={{ listStyleType: 'disc', pl: 2 }}>
          <ListItem sx={{  display: 'list-item' }}>
            What's it going to cost me altogether to get my <Link href="/search?q=cat%20spay">cat spayed</Link>?
          </ListItem>
          <ListItem sx={{  display: 'list-item' }}>
            Does my dentist generally charge more than other local <Link href="/search?q=dental">dentists</Link>?
          </ListItem>
          <ListItem sx={{  display: 'list-item' }}>
            Who charges the least for <Link href="/search?q=lawn%20mow">lawn mowing</Link> in my area?
          </ListItem>
          <ListItem sx={{  display: 'list-item' }}>
            What out-the-door prices have people been getting for a <Link href="/search?q=2023%20bolt">Chevy Bolt</Link>, and at what car dealerships?
          </ListItem>
        </List>
        <Typography component="h2" variant="h5" mt={3} mb={1}>
          Why isn't the info super detailed?
        </Typography>
        <Typography component="p" mt={1}>
          It's quick and easy to post a bill of your own to BillDB's database.
          The tradeoff is that there's little room for details, and therefore there may be some ambiguity.
          It's best to keep in mind that there's limited precision for any given data point, regardless of the level of detail.
        </Typography>
        <Typography component="h2" variant="h5" mt={3} mb={1}>
          How do I contribute?
        </Typography>
        <Typography component="p" mt={1}>
          You can add bills of your own via the "submit" link. Some tips:
        </Typography>
        <List dense={true} sx={{ listStyleType: 'disc', pl: 2 }}>
          <ListItem sx={{  display: 'list-item' }}>
            By design, there's not a lot of room for detail.
            Just be brief and give only the essential info.
          </ListItem>
          <ListItem sx={{  display: 'list-item' }}>
            When you start to enter the description of your bill, descriptions for other bills that are already in the database will appear as options.
            Consider using one of those if it's suitable.
          </ListItem>
          <ListItem sx={{  display: 'list-item' }}>
            You can include tax, tip, and/or other fees in the total cost.
            In fact, including the tip for, say, an ice cream purchase can help better represent how a given shop differs in price from one that doesn't accept tips.
          </ListItem>
        </List>

        <Typography component="h2" variant="h5" mt={3} mb={1}>
          What kind of bills can I submit?
        </Typography>
        <Typography component="p" mt={1}>
          You can submit pretty much anything, though there are some prices that would be of low value to share here.
          On one hand, products from online retailers, hotel rooms, airline tickets, or anything else for which it's already very easy to comparison shop aren't well-suited for the site.
          On the other, out-the-door car prices are useful since these can vary significantly from advertised prices.
          Even something as simple as the final cost of an ice cream cone can be interesting given that some shops might expect a large tip while others prohibit it.
        </Typography>

        <Typography component="h2" variant="h5" mt={3} mb={1}>
          How does the location search box work?
        </Typography>
        <Typography component="p" mt={1}>
          BillDB will show search results centered around the specified location.
          If you leave it blank, BillDB will generally only have a rough sense of your location.
          For greater accuracy, tap the pin icon inside the location search box, or enter a location manually.
        </Typography>
        <Typography component="p" mt={1}>
          Pro Tip: You can enter the name of a given business, leaving the product search box empty, to see all bills posted for that business.
        </Typography>

      </Container>
    </Box>
  );
}

export default About;