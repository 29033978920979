import React, { useState, useEffect, useRef } from "react"
import { Box, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, Input, FormControl, InputLabel, FormHelperText, SelectChangeEvent } from "@mui/material";
import { reportAction } from "../util/metrics";

export type UsernameDialogProps = {
  currentUsername: string,
  handleSelectedUsername: (selectedUsername: string) => void,
  handleCancel: () => void
}

const UsernameDialog = ({ currentUsername, handleSelectedUsername, handleCancel }: UsernameDialogProps) => {
  const [selectedUsername, setSelectedUsername] = useState("");
  const [error, setError] = useState("");

  // console.log(`selectedUsername: ${selectedUsername}`);

  useEffect(() => {
    reportAction("show_username_dialog", "engagement");
  }, [])

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const username = event.target.value.trim();

    if (username.length > 0) {
      if (username.length < 3 || username.length > 24) {
        setError("Must be 3-24 characters long");
        return;
      }

      if (username.includes(" ")) {
        setError("May not contain spaces");
        return;
      }

      if (!username.match(/^[\p{Letter}\p{Nd} _.-]+$/u)) {
        setError("Must only consist of letters/numbers");
        return;
      }

      if (!username.match(/^\p{Letter}/u)) {
        setError("Must start with at least one letter");
        return;
      }
    }

    setError("");
    setSelectedUsername(username);
  }

  return (
    <Box>
      <DialogTitle>
        Set Username
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter a username to be shown with bills you submit, or leave it blank to post anonymously.
        </DialogContentText>
        <FormControl error={error !== ""} variant="standard" sx={{
          mt: 2
        }}>
          <InputLabel htmlFor="component-error">Username</InputLabel>
          <Input
            id="username-input"
            defaultValue={currentUsername}
            aria-describedby="username input"
            onChange={handleOnChange}
          />
          {error &&
            <FormHelperText id="component-error-text">{error}</FormHelperText>
          }
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button disabled={error !== ""} onClick={() => handleSelectedUsername(selectedUsername)}>Save</Button>
      </DialogActions>
    </Box>
  );
}

export default UsernameDialog;