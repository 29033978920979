import React, { useState, useEffect, useRef } from "react"
import { Box, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, TextField, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import currencySymbolMap from 'currency-symbol-map'
import { localeToCurrencyCode } from "../util/currencyUtils";
import * as cc from 'currency-codes';
import { reportAction } from "../util/metrics";

export type CurrencyDialogProps = {
  currentCurrencyCode: string,
  handleSelectedCurrencyCode: (selectedCurrencyCode: string) => void,
  handleCancel: () => void
}

type EntryType = {
  code: string,
  symbol: string
}

const generateMenuItems = () => {
  // const codes = Intl.supportedValuesOf("currency");
  const codes = cc.codes();
  let entries: EntryType[] = [];
  codes.forEach(code => {
    const symbol = currencySymbolMap(code);
    if (symbol) {
      entries.push({
        code: code,
        symbol: symbol
      });
    }
  });

  return entries;
}

const CurrencyDialog = ({ currentCurrencyCode, handleSelectedCurrencyCode, handleCancel }: CurrencyDialogProps) => {
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState("");

  useEffect(() => {
    reportAction("show_currency_dialog", "engagement");
  }, []);

  const menuItems = useRef<EntryType[]>();
  if (!menuItems.current) {
    menuItems.current = generateMenuItems();
  }

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedCurrencyCode(event.target.value as string);
  };

  useEffect(() => {
    const locale = Intl.NumberFormat().resolvedOptions().locale;
    const localeCurrencyCode = localeToCurrencyCode(locale);
    const code = currentCurrencyCode ? currentCurrencyCode : localeCurrencyCode;
    setSelectedCurrencyCode(code);
    // console.log(`currentCurrencyCode: ${currentCurrencyCode}`);
    // console.log(`localeCurrencyCode: ${localeCurrencyCode}`);
    // console.log(`code: ${code}`);
  }, [currentCurrencyCode]);

  // console.log(`selectedCurrencyCode: ${selectedCurrencyCode}`);

  return (
    <Box>
      <DialogTitle>
        Bill Submission Currency
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText>
          You will use this currency when you submit a bill.
        </DialogContentText> */}
        <FormControl fullWidth sx={{
          mt: 2
        }}>
          <InputLabel id="demo-simple-select-label">Currency</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCurrencyCode}
            label="Currency"
            onChange={handleChange}
          >
            {menuItems.current.map(item => (
              <MenuItem key={item.code} value={item.code}>{item.code} - {currencySymbolMap(item.code)}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={() => handleSelectedCurrencyCode(selectedCurrencyCode)}>Save</Button>
      </DialogActions>
    </Box>
  );
}

export default CurrencyDialog;