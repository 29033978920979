import React, { useEffect } from "react";
import { Box, Typography, Link, List, ListItem } from "@mui/material";
import image from "../images/blog/housepainter.webp";
import * as metrics from "../../util/metrics";

const BlogPostHousepainters = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    metrics.reportPageLoad("Blog", window.location.href,  window.location.pathname);
  }, [])

  return (
    <Box>
      <Link href="/blog/cost_to_paint_my_house" underline="hover">
        <Typography
          component="h2"
          variant="h5"
          mt={3}
          mb={1}
          fontSize='2rem'
          color='#242424'
        >
          Housepainters Quoted Me $9k to $28k to Paint My House
        </Typography>
      </Link>
      <Typography
        pb={1}
      >
        <time>May 10, 2024</time>
      </Typography>

      <Box
        mt={3}
        mb={2}
      >
        <img
          src={image}
          alt="House painter with dollar signs"
          loading="lazy"
          style={{
            height: "300px",
            objectFit: "cover",
            width: "100%"
          }}
        />
      </Box>

      <Typography component="p" mt={2}>
        My house's exterior paint is getting pretty faded and is peeling in places, especially where it gets a lot of direct sun.
        It's time for fresh paint so it'll look great again and be protected for another decade or more.
      </Typography>
      <Typography component="p" mt={2}>
        While I know having paint in good condition is vital for protecting your house's siding and trim, I've been putting it off given how much I've been expecting it to cost.
        When I finally bit the bullet and got a bunch of quotes, I was pleasantly surprised by some and a little... amused by others.
      </Typography>
      <Typography component="p" mt={2}>
        A piece of advice if you're getting your house painted: <b>Get at least a half dozen quotes if you can.</b>
      </Typography>
      <Typography component="p" mt={2}>
        If you were to, say, pick the top 2 or 3 painters listed on a site such as Yelp, you might find that they're popular enough to be able to charge you many thousands more than others.
        Even if you were to pick a few at random, there's a chance you'll end up with quotes that are all at the upper end of the scale.
      </Typography>
      <Typography component="p" mt={2}>
        Here in Seattle, my quote from one business was nearly <i>three times</i> that of several other very highly-rated but smaller and less aggressively marketed businesses.
        While I wasn't particularly surprised that the most well-known company quoted by far the highest price, I found some other interesting things:
      </Typography>
      <Typography component="p" mt={2}>
        I've submitted the quotes I received to the BillDB database — you can see them here:
      </Typography>
      <Typography component="p" mt={2}>
        <Link href="/search?q=house%20paint" target="_blank" rel="noopener">Exterior house painting cost</Link>
      </Typography>
      <List dense={true} sx={{ listStyleType: 'disc', pl: 2 }}>
        <ListItem sx={{ display: 'list-item', pl: 0 }}>
          The estimator for the business with the most brand recognition and by far the highest quote seemed to be the least knowledgeable (making some incorrect observations), though he was among the most personable.
        </ListItem>
        <ListItem sx={{ display: 'list-item', pl: 0 }}>
          The housepainters I reached out to after seeing them do a good job on my neighbor's house gave me one of the highest quotes despite having no online presence.
        </ListItem>
        <ListItem sx={{ display: 'list-item', pl: 0 }}>
          Similarly, some other painters with a limited online presence gave higher quotes than others that have more visible, positive reputations.
          <i>Given that online ratings can be vital to a given business nowadays, I prefer hiring a business that has a reputation to uphold and is eager to please.</i>
        </ListItem>
      </List>
      <Typography component="p" mt={2}>
        Something else to consider: Getting a bunch of quotes can help you get more perspective on what's needed and might even give you some ideas.
        Three out of the eight estimators who visited my house pointed out a particular rotten board that would need to be replaced.
        Two estimators pointed out some additional areas that were questionable, and I was impressed when one estimator pointed out an unusual detail with my house trim's color scheme and asked if I wanted it painted differently.
      </Typography>
      <Typography component="p" mt={2}>
        Another piece of advice: Check ratings on multiple sites.
        I cross-refercened Yelp, Google, and Thumbtack, ultimately reaching out to local businesses that had a sufficient number of positive, relevant reviews and no major concerns.
      </Typography>
      <Typography component="p" mt={2}>
        Want to see what quotes others in the BillDB community received?
        Try <Link href="/search?q=paint" target="_blank" rel="noopener">searching</Link> the BillDB database, and don't forget to <Link href="/submit" target="_blank" rel="noopener">submit</Link> your own quotes!
      </Typography>
    </Box>
  );
}

export default BlogPostHousepainters;