import axios from 'axios';
import { User } from "firebase/auth";

export enum AuthProviderType {
  Google = "GOOGLE",
  Facebook = "FACEBOOK",
  Github = "GITHUB",
  Apple = "APPLE",
  Email = "EMAIL",
  Twitter = "TWITTER",
}

export const reportSignIn = async (provider: AuthProviderType, emailAddress: string) => {

  const signInData = {
    provider: provider,
    email: emailAddress,
  };

  try {
		const authToken = localStorage.getItem('AuthToken');
    if (!authToken) {
      console.error("No authToken");
      return;
    }

    axios.defaults.headers.common = { Authorization: `${authToken}` };
    const response = await axios.post('/api/user/signedIn', signInData);

    console.log(`response: ${JSON.stringify(response)}`);
    // const customToken = response.data.idtoken;

    // if (!customToken) {
    //   console.log(`${reportSignIn.name} - failed to get custom token`);
    //   return;
    // }

    // const userCredential = await signInWithCustomToken(firebaseAuth, customToken);
    // const jwtToken = await userCredential.user.getIdToken();
    // localStorage.setItem('AuthToken', `Bearer ${jwtToken}`);;
    // setloading(false);
    // signUpComplete();
    // navigate('/');
  } catch (err: any) {
    // setloading(false);

    console.log(`${reportSignIn.name} - err: ${JSON.stringify(err)}`);

    // TODO: handle errors

    // If error is from our API, handle it
    if (err.response && err.response.data) {
      console.log(`${reportSignIn.name} - err.response.data: ${JSON.stringify(err.response.data)}`);
      // setErrors(err.response.data);
      return;
    }

    // Handle error from Firebase Auth
    switch (err.code) {
      case "auth/invalid-email": {
        // setErrors({
        //   email: "Invalid email address"
        // });
        break;
      }
      // TODO:
      default: {
        console.log(`${reportSignIn.name} - err: ${err}`);
        // setErrors({
        //   general: err.code
        // });
        break;
      }
    }
  }
}

// const authToken = localStorage.getItem('AuthToken');
// export const getOrRefreshIdToken = async () => {
//   console.log("getOrRefreshIdToken");
//   const auth = getAuth();

//   if (!auth) {
//     console.log("getOrRefreshIdToken - No auth");
//     return "";
//     // throw new Error("No auth");
//   }

//   if (!auth.currentUser) {
//     console.log("getOrRefreshIdToken - No auth.currentUser");
//     return "";
//     // throw new Error("No auth.currentUser");
//   }

//   const idToken = await auth.currentUser.getIdToken(false);
//   console.log(`getOrRefreshIdToken - idToken: ${JSON.stringify(idToken)}`);
//   // localStorage.setItem('AuthToken', `Bearer ${idToken}`);

//   // return idToken;
//   return `Bearer ${idToken}`;
// }

export const getOrRefreshIdToken = async (authCurrentUser: User) => {
  // console.log("getOrRefreshIdToken");

  if (!authCurrentUser) {
    // console.log("getOrRefreshIdToken - No authCurrentUser");
    return "";
    // throw new Error("No auth");
  }

  const idToken = await authCurrentUser.getIdToken(false);
  // console.log(`getOrRefreshIdToken - idToken: ${JSON.stringify(idToken)}`);
  // localStorage.setItem('AuthToken', `Bearer ${idToken}`);

  // return idToken;
  return `Bearer ${idToken}`;
}
