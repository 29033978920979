import React, { useState, useEffect, useCallback } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import TablePagination from '@mui/material/TablePagination';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { IntlProvider } from 'react-intl';

import { htmlDecode } from '../util/inputValidation';

import axios from 'axios';

import { formatCost } from '../util/currencyUtils';

// import IntlMessageFormat from 'intl-messageformat';

export type SearchResultsRow = {
  userId: string;
  billId: string,
  product: string,
  productNormalized: string;
  currencyCode: string,
  cost: number,
  placeNameMain: string,
  placeNameSecondary: string,
  placeId: string,
  includesTaxes: boolean,
  includesTipOrFees: boolean,
  includesCoupons: boolean,
  includesInsurance: boolean,
  isQuote: boolean,
  byBusiness: boolean,
  createdAtSecs: number,
  // notes: string,
  intlLocale: string,
  _geoloc: { lat: number, lng: number }
}

type RowProps = {
  row: SearchResultsRow,
}

// const locale = new IntlMessageFormat('', 'en-us').resolvedOptions().locale;
const locale = Intl.NumberFormat().resolvedOptions().locale;

const Row = (props: RowProps) => {
  // const [userAndDate, setUserAndDate] = useState("");

  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const { row } = props;
  // console.log("row: " + JSON.stringify(row));
  const [open, setOpen] = React.useState(false);
  const dateSubmitted = new Date(row.createdAtSecs * 1000).toLocaleDateString();
  const mapUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyCDnEZTjSbioCqnkOF5Zljh6T-ugAfQ1ds
  &q=place_id:${row.placeId}&zoom=10`;
  const paidOrQuoted = row.isQuote ? "Quoted" : "Paid";

  const [username, setUsername] = useState<string>("An anonymous user");

  const refresh = useCallback((username: string) => {
    const items: string[] = [];

    items.push(`${username} posted this bill on ${dateSubmitted}.`);

    const parts = [];

    if (row.includesTaxes) {
      parts.push("taxes");
    }

    if (row.includesTipOrFees) {
      parts.push("tips and/or other fees");
    }

    if (row.includesInsurance) {
      parts.push("the amount billed to insurance");
    }

    if (row.includesCoupons) {
      parts.push("coupon discounts");
    }

    if (parts.length > 0) {
      let inclusions = "It includes ";
      if (parts.length === 1) {
        inclusions += parts[0];
      } else if (parts.length === 2) {
        inclusions += parts.join(" and ");
      } else {
        inclusions += parts[0];
        for (let i = 1; i < parts.length - 1; i++) {
          inclusions += ", " + parts[i];
        }
        inclusions += ", and " + parts[parts.length - 1];
      }
      inclusions += ".";
      items.push(inclusions);
    }

    // if (row.includesTaxes && row.includesTipOrFees && row.includesInsurance && row.includesCoupons) {
    //   items.push(`It includes taxes, other fees, coupon discounts, and the amount billed to insurance.`);
    // } else if (row.includesTaxes && row.includesTipOrFees && row.includesInsurance) {
    //   items.push(`It includes taxes, other fees, and the amount billed to insurance.`);
    // } else if (row.includesTaxes && row.includesTipOrFees) {
    //   items.push(`It includes taxes plus tips and/or other fees.`);
    // } else if (row.includesTaxes && row.includesInsurance) {
    //   items.push(`It includes taxes and the amount billed to insurance.`);
    // } else if (row.includesTipOrFees && row.includesInsurance) {
    //   items.push(`It includes fees and the amount billed to insurance.`);
    // } else {
    //   if (row.includesTaxes) {
    //     items.push(`It includes taxes.`);
    //   }

    //   if (row.includesTipOrFees) {
    //     items.push(`It includes tip and/or other fees.`);
    //   }

    //   if (row.includesInsurance) {
    //     items.push(`It includes the amount billed to insurace.`);
    //   }
    // }

    if (row.isQuote) {
      items.push(`This is a quoted rather than a paid amount.`);
    }

    if (row.byBusiness) {
      items.push(`This was purportedly submitted by the business.`);
    }

    setExpandedItems(items);
  }, [dateSubmitted, row.includesInsurance, row.includesTaxes, row.includesTipOrFees, row.includesCoupons, row.isQuote, row.byBusiness]);

  useEffect(() => {
    refresh(username);
  }, [username, refresh])

  useEffect(() => {
    if (open) {
      axios.get(`/api/user/getUsername/${row.userId}`)
        .then((response) => {
          if (response.data.username) {
            setUsername(`${response.data.username}`);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [open, row.userId])

  const expandRow = async () => {
    setOpen(!open);
  }

  // const fontFamily = "monospace";
  const fontFamily = "inherit";
  const fontSize = "0.9rem";
  const fontSizeSmall = "0.7rem";
  const colorLight = "#777";

  return (
    <IntlProvider locale={row.intlLocale}>

    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ verticalAlign: "top", p: 1, pl: 0 }}>
          <Typography fontSize={fontSizeSmall}>{paidOrQuoted}</Typography>
          <Typography fontSize={fontSize} fontFamily={fontFamily} fontWeight={700}>{formatCost(locale, row.currencyCode, row.cost)}</Typography>
        </TableCell>
        <TableCell sx={{ verticalAlign: "top", p: 1, minWidth: "80px" }}>
        {/* <Typography component="span" fontSize="0.7rem">for</Typography> <Typography component="span">{row.productNormalized}</Typography> */}
          {/* <Typography fontSize="0.7rem">for</Typography> */}
          <Typography fontSize={fontSize} fontFamily={fontFamily}>{htmlDecode(row.productNormalized)}</Typography>
          {/* <Typography fontSize={fontSize} fontFamily={fontFamily}>{decodeURIComponent(row.productNormalized)}</Typography> */}
        </TableCell>
        <TableCell sx={{ verticalAlign: "top", p: 1 }}>
          {/* <Typography component="span" fontSize="0.7rem">at</Typography> <Typography component="span">{row.place}</Typography> */}
          {/* <Typography fontSize="0.7rem">at</Typography> */}
          <Typography fontSize={fontSize} fontFamily={fontFamily}>{htmlDecode(row.placeNameMain)}</Typography>
          <Typography fontSize={fontSizeSmall} fontFamily={fontFamily} color={colorLight}>{htmlDecode(row.placeNameSecondary)}</Typography>
        </TableCell>
        <TableCell align="right" sx={{ pr: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => expandRow()}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{
              // backgroundColor: "lightgray"
            }}>

              <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <nav aria-label="main mailbox folders">
                  <List dense={true}>
                    <ListItem disablePadding sx={{
                      display: "inline-block"
                    }}>
                      {expandedItems.map(item => (
                        <ListItemText key={item} primary={item} />
                      ))}
                    </ListItem>
                    {/* <ListItem disablePadding>
                      <ListItemText primary="Report this" />
                    </ListItem> */}
                  </List>
                </nav>
              </Box>

              <Box
                component="iframe"
                allowFullScreen
                height="300px"
                width="100%"
                loading="lazy"
                title="business location map"
                border="none"
                // referrerpolicy="no-referrer-when-downgrade"
                src={mapUrl}
                >
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>

    </IntlProvider>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    billId: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
    productNormalized: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
    placeNameMain: PropTypes.string.isRequired,
    placeNameSecondary: PropTypes.string.isRequired,
    createdAtSecs: PropTypes.number.isRequired,
    // notes: PropTypes.string.isRequired,
    _geoloc: PropTypes.object.isRequired
  }).isRequired,
};

export type SearchResultsTableProps = {
  rows: SearchResultsRow[];
}

const SearchResultsTable: React.FunctionComponent<SearchResultsTableProps> = ({ rows }): JSX.Element => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      rows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [rows, page, rowsPerPage],
  );

  const theme = useTheme();

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ width: '100%', mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>

            <Table aria-label="collapsible table">
              {/* <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead> */}
              <TableBody>
                {visibleRows.map((row) => (
                  <Row key={row.billId} row={row} />
                ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>

          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={useMediaQuery(theme.breakpoints.up('sm')) ? [5, 10, 25]: []}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>

  );
}

export default SearchResultsTable;
