import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyCJxoUi7tSKmZijiYvoJOzD4ytqEHd8_Bg",
//   authDomain: "todoapp-13480.firebaseapp.com",
//   projectId: "todoapp-13480",
//   storageBucket: "todoapp-13480.appspot.com",
//   messagingSenderId: "782787451907",
//   appId: "1:782787451907:web:9c2afe3bcdf968d6b9c236",
//   measurementId: "G-Q6VKNFX7VR"
// };
const firebaseConfig = {
  apiKey: "AIzaSyBc6yuHhWoqEI-vIFI8ArzXUigDT9bhyK8",
  authDomain: "verdantlabs-billdb.firebaseapp.com",
  projectId: "verdantlabs-billdb",
  storageBucket: "verdantlabs-billdb.appspot.com",
  messagingSenderId: "948275203543",
  appId: "1:948275203543:web:2954ff31236395d68fcf92",
  measurementId: "G-3CLLK2QW52"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const isDevelopment = window.location.hostname === 'localhost';
let auth;
if (isDevelopment) {
  auth = getAuth();
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
} else {
  auth = getAuth(app);
}

export const firebaseAuth = auth;

// export const firebaseAuth = getAuth(app);
// export const firebaseAuth = getAuth();
// connectAuthEmulator(firebaseAuth, "http://127.0.0.1:9099");
