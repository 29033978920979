import React, { useState, useEffect } from 'react';
import { Dialog } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui//material/Box';
import Container from '@mui//material/Container';
import { IntlProvider } from 'react-intl';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import CurrencyDialog from './currencyDialog';
import UsernameDialog from './usernameDialog';
import ChangePasswordDialog from './changePasswordDialog';
import DeleteAccountDialog from './deleteAccountDialog';
import { sendPasswordResetEmail, User } from 'firebase/auth';
import { firebaseAuth } from '../util/firebase';
import axios from 'axios';
import { getOrRefreshIdToken } from '../util/auth';
import * as metrics from "../util/metrics";

const classes = { content: "content", toolbar: "toolbar", details: "details", root: "root", progress: "progress", title: "title", form: "form",
                  uiProgress: "uiProgress", pos: "pos", customError: "customError", submitButton: "submitButton", dialogStyle: "dialogStyle"}

export type AccountProps = {
  submissionCurrencyCode: string,
  setSubmissionCurrencyCode: (newCode: string) => void,
  showSignIn: () => void,
  authCurrentUser: User | undefined,
}

function Account ({ submissionCurrencyCode, setSubmissionCurrencyCode, showSignIn, authCurrentUser }: AccountProps) {
  const [userCurrencyCode, setUserCurrencyCode] = useState("");
  const [username, setUsername] = useState("");
  const [currencyDialogOpen, setCurrencyDialogOpen] = useState(false);
  const [usernameDialogOpen, setUsernameDialogOpen] = useState(false);
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    metrics.reportPageLoad("Account", window.location.href, window.location.pathname);
  }, [])

	useEffect ( () => {
		// authMiddleWare(navigate);

    // console.log(`Account useEffect`);

    if (!authCurrentUser) {
      return;
    }

    getOrRefreshIdToken(authCurrentUser).then(authToken => {
      // console.log(`Account useEffect - authToken: ${authToken}`);
      if (authToken) {
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        axios.get('/api/user')
          .then((response) => {
            // setEmail(response.data.userCredentials.email);
            setUsername(response.data.userCredentials.username);
            // setUserId(response.data.userCredentials.userId);
            setUserCurrencyCode(response.data.userCredentials.currencyCode);
            setSubmissionCurrencyCode(response.data.userCredentials.currencyCode);
            // setUiLoading(false);
          })
          .catch((error) => {
            // if (error.response?.status === 403) {
            //   showSignIn();
            // }
            // console.error(error);
            // setErrorMsg('Error in retrieving the data')
            showSignIn();
          });
      } else {
        showSignIn();
      }
    })
  }, [showSignIn, setSubmissionCurrencyCode, authCurrentUser]);

  const handleOpenCurrencyDialog = () => {
    setCurrencyDialogOpen(true);
  }

  const handleOpenUsernameDialog = () => {
    setUsernameDialogOpen(true);
  }

  const handleOpenChangePasswordDialog = () => {
    setChangePasswordDialogOpen(true);
  }

  const handleOpenDeleteAccountDialog = () => {
    setDeleteAccountDialogOpen(true);
  }

  const saveCurrencyCode = async (newCurrencyCode: string) => {
    if (!authCurrentUser) {
      showSignIn();
      return;
    }

		const authToken = await getOrRefreshIdToken(authCurrentUser);
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.post('/api/user/setCurrency', {
        currencyCode: newCurrencyCode
      })
			.then(() => {
        setUserCurrencyCode(newCurrencyCode);
        setSubmissionCurrencyCode(newCurrencyCode);
				// setbuttonLoading(false);
			})
			.catch((error) => {
				if (error.response?.status === 403) {
          showSignIn();
				}
				console.error(error);
				// setbuttonLoading(false)
			});
  }

  const saveUsername = async (newUsername: string) => {
    if (!authCurrentUser) {
      showSignIn();
      return;
    }

		const authToken = await getOrRefreshIdToken(authCurrentUser);
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.post('/api/user/setUsername', {
        username: newUsername
      })
			.then(() => {
        setUsername(newUsername);
				// setbuttonLoading(false);
			})
			.catch((error) => {
				if (error.response?.status === 403) {
          showSignIn();
				}
				console.error(error);
				// setbuttonLoading(false)
			});
  }

  return (
    <IntlProvider locale={navigator.language}>
      <Container
        component="main"
        className={classes.content}
        sx={{
          // flexGrow: 1,
          marginTop: 2,
          maxWidth: "100%",
          display: "flex",
          justifyContent: "center"
        }}
      >

        <Dialog open={currencyDialogOpen}>
          <CurrencyDialog
            currentCurrencyCode={userCurrencyCode}
            handleSelectedCurrencyCode={selectedCurrencyCode => {
              saveCurrencyCode(selectedCurrencyCode);
              setCurrencyDialogOpen(false);
            }}
            handleCancel={() => setCurrencyDialogOpen(false)}
          />
        </Dialog>

        <Dialog open={usernameDialogOpen}>
          <UsernameDialog
            currentUsername={username}
            handleSelectedUsername={selectedUsername => {
              saveUsername(selectedUsername);
              setUsernameDialogOpen(false);
            }}
            handleCancel={() => setUsernameDialogOpen(false)}
          />
        </Dialog>

        <Dialog open={changePasswordDialogOpen}>
          <ChangePasswordDialog
            handleSendChangePasswordEmail={() => {
              if (firebaseAuth.currentUser?.email) {
                sendPasswordResetEmail(firebaseAuth, firebaseAuth.currentUser?.email, {
                  url: "https://billdb.com"
                })
                .then(() => {
                  setChangePasswordDialogOpen(false);
                  enqueueSnackbar('Request submitted', { variant: 'success' });
                })
                .catch((errorResponse) => {
                  setChangePasswordDialogOpen(false);
                  // console.log(`errorResponse: ${errorResponse}`);
                  // console.log(errorResponse?.response?.data?.error);
                  // if (errorResponse?.response?.data?.error === "auth/invalid-email") {
                  //   enqueueSnackbar('Invalid email address', { variant: 'error' });
                  // } else {
                    enqueueSnackbar('An error occurred', { variant: 'error' });
                  // }
                });
              }
            }}
            handleCancel={() => setChangePasswordDialogOpen(false)}
          />
        </Dialog>

        <Dialog open={deleteAccountDialogOpen}>
          <DeleteAccountDialog
            handleDeleteAccount={() => {
              axios.get('/api/user/deleteAccount')
                .then((response) => {
                  // console.log("response: " + JSON.stringify(response));
                  setDeleteAccountDialogOpen(false);
                  enqueueSnackbar('Account deleted', { variant: 'success' });
                  showSignIn();
                })
                .catch((err) => {
                  // console.log(`err: ${err}`);
                  setDeleteAccountDialogOpen(false);
                  enqueueSnackbar('An error occurred', { variant: 'error' });
                });

            }}
            handleCancel={() => setDeleteAccountDialogOpen(false)}
          />
        </Dialog>

        <Box p={2} sx={{
          maxWidth: "450px"
        }}>
          <SnackbarProvider maxSnack={1} />
          <Box
            alignItems="flex-start"
          >
            <Typography component="h2" variant="h6">
              Account Settings
            </Typography>

            <Divider
              sx={{
                mt: 3,
                mb: 3
              }}
            >
              {/* Currency */}
            </Divider>

            <Stack>
              <Typography>
                Specify the currency to use when you submit bills.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  mt: 3
                }}
                onClick={handleOpenCurrencyDialog}
              >
                Set Currency
              </Button>
            </Stack>

            <Divider
              sx={{
                mt: 4,
                mb: 3
              }}
            >
              {/* Username */}
            </Divider>

            <Stack>
              <Typography>
                Optionally specify a username to be displayed with your submissions.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  mt: 3
                }}
                onClick={handleOpenUsernameDialog}
              >
                Set Username
              </Button>
            </Stack>

            <Divider
              sx={{
                mt: 4,
                mb: 3
              }}
            >
              {/* Password */}
            </Divider>

            <Stack>
              <Typography>
                Keep your account safe with a secure password.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  mt: 3
                }}
                onClick={handleOpenChangePasswordDialog}
              >
                Change Password
              </Button>
            </Stack>

            <Divider
              sx={{
                mt: 4,
                mb: 3
              }}
            >
              {/* Account */}
            </Divider>

            <Stack>
              <Typography>
                Deleting your account will also delete your posts.
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  mt: 3
                }}
                onClick={handleOpenDeleteAccountDialog}
              >
                Delete Account
              </Button>
            </Stack>

          </Box>
        </Box>
      </Container>
    </IntlProvider>
  );

}

export default Account;
