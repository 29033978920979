import React, { useEffect } from 'react';
import { Box, Typography, IconButton, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { reportAction } from '../util/metrics';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxWidth: "100%",
  maxHeight: "100%",
  overflowY: "scroll"
};

export type SearchInfoModalProps = {
  handleClose: () => void
}

// const SearchInfoModal = ({ handleClose }: SearchInfoModalProps) => {
const SearchInfoModal = React.forwardRef(({ handleClose }: SearchInfoModalProps, ref) => {
  useEffect(() => {
    reportAction("show_search_info_modal", "engagement");
  }, []);

  return (
    <Box sx={style}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6" component="h2" id="search-info-modal-title">
          How does this work?
        </Typography>
        <Box>
          <IconButton
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box id="search-info-modal-description">
        <Typography component="p" mt={2}>
          Enter a <b>product</b> or <b>service</b> into the search box.
        </Typography>
        <Typography component="p" mt={1}>
          You'll then see what other people paid for it, ordered by distance from the given location.
        </Typography>
        <Typography component="p" mt={1}>
          BillDB is community-powered and only contains what people like you have submitted so far.
        </Typography>
        <Typography component="p" mt={1}>
          Want to see more search results?
          {' '}<b><Link href="/submit">Submit a bill</Link> to help!</b>
          {/* You can help out by submitting bills of your own. */}
        </Typography>
        <Typography component="p" mt={1}>
          <i>
            Bills are user-contributed, details are limited, and prices may change over time.
            You might not get the same price as posted here.
          </i>
        </Typography>
      </Box>
    </Box>
  );
});

export default SearchInfoModal;
