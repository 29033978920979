import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, List, ListItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui//material/Box';
import Container from '@mui//material/Container';
// import Link from '@mui/material/Link';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import getSymbolFromCurrency from 'currency-symbol-map'
import clsx from 'clsx';
import axios from 'axios';
// import { Wrapper, Status } from "@googlemaps/react-wrapper";
// import ErrorComponent from "./errorComponent";
import ProductAutocomplete from './productAutocomplete';
import PlaceAutocomplete, {GooglePlace} from './placeAutocomplete';
import { IntlProvider } from 'react-intl';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import Modal from '@mui/material/Modal';
import SubmitInfoModal from './submitInfoModal';
import InputAdornment from '@mui/material/InputAdornment';
import { localeToCurrencyCode } from '../util/currencyUtils';
import CurrencyDialog from './currencyDialog';
import { MAX_COST_CHARS } from '../util/inputValidation';
import { getOrRefreshIdToken } from '../util/auth';
import { User } from 'firebase/auth';
import * as metrics from "../util/metrics";
import { reportAction } from '../util/metrics';

// const placesApiKey = "AIzaSyCDnEZTjSbioCqnkOF5Zljh6T-ugAfQ1ds";

const classes = { content: "content", toolbar: "toolbar", root: "root", details: "details", avatar: "avatar", locationText: "locationText", buttonProperty: "buttonProperty",
                  uiProgress: "uiProgress", progress: "progress", uploadButton: "uploadButton", customError: "customError", submitButton: "submitButton"}


export type addBillProps = {
  submissionCurrencyCode: string,
  setSubmissionCurrencyCode: React.Dispatch<React.SetStateAction<string>>,
  showSignIn: () => void,
  showSignUp: () => void,
  autocompleteService: any,
  openRewardInfoModal: () => void,
  authCurrentUser: User | undefined,
  isBusiness: boolean,
  setIsBusiness: (isBusiness: boolean) => void,
}

const AddBill = ({ submissionCurrencyCode, setSubmissionCurrencyCode, showSignIn, showSignUp, autocompleteService, openRewardInfoModal, authCurrentUser }: addBillProps) => {
  const [product, setProduct] = useState("");
  const [cost, setCost] = useState<number | undefined>();
  const [placeNameMain, setPlaceNameMain] = useState("");
  const [placeNameSecondary, setPlaceNameSecondary] = useState("");
  // const [notes, setNotes] = useState("");
	const [buttonLoading, setbuttonLoading] = useState(false);
  const [submitInfoModalOpen, setSubmitInfoModalOpen] = useState(false);
  const [intlLocale, setIntlLocale] = useState<string>();
  const [cardShown, setCardShown] = React.useState(true);
  const [includesTaxes, setIncludesTaxes] = useState(false);
  const [includesTipOrFees, setIncludesTipOrFees] = useState(false);
  const [includesCoupons, setIncludesCoupons] = useState(false);
  const [includesInsurance, setIncludesInsurance] = useState(false);
  const [isQuote, setIsQuote] = useState(false);
  const [byBusiness, setByBusiness] = useState(false);
  const [selectedGooglePlace, setSelectedGooglePlace] = useState<GooglePlace>();
  const [userCurrencyCode, setUserCurrencyCode] = useState("");
  const [currencyDialogOpen, setCurrencyDialogOpen] = useState(false);
  const [submitPending, setSubmitPending] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    metrics.reportPageLoad("Submit", window.location.href,  window.location.pathname);
  }, [])

  // TODO: share this code with Account
	useEffect ( () => {
    const locale = Intl.NumberFormat().resolvedOptions().locale;
    setIntlLocale(locale);
    // console.log("locale: " + locale);

    if (!authCurrentUser) {
      return;
    }

    // console.log("useEffect - getting currency code");
    getOrRefreshIdToken(authCurrentUser).then(authToken => {
      if (authToken) {
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        axios.get('/api/user')
          .then((response) => {
            let code = response.data.userCredentials.currencyCode;
            // console.log("useEffect - user currency code: " + code);
            setUserCurrencyCode(code);
            if (code) {
              setSubmissionCurrencyCode(code);
            } else {
              if (locale) {
                const localeCurrencyCode = localeToCurrencyCode(locale);
                // console.log(`localeCurrencyCode: ${localeCurrencyCode}`);
                setSubmissionCurrencyCode(localeCurrencyCode);
              } else {
                console.warn("Couldn't get locale.");
                setSubmissionCurrencyCode("USD");
              }
            }
            // setUiLoading(false);
          })
          .catch((error) => {
            if (error.response?.status === 403) {
              showSignIn();
            }
          });
      } else {
        showSignIn();
      }
    })
	}, [showSignIn, setSubmissionCurrencyCode, authCurrentUser]);

  // TODO: share this code with Account
  const saveCurrencyCode = async (newCurrencyCode: string) => {
    if (!authCurrentUser) {
      showSignIn();
      return;
    }

		const authToken = await getOrRefreshIdToken(authCurrentUser);
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.post('/api/user/setCurrency', {
        currencyCode: newCurrencyCode
      })
			.then(() => {
        setUserCurrencyCode(newCurrencyCode);
        setSubmissionCurrencyCode(newCurrencyCode);
				// setbuttonLoading(false);
			})
			.catch((error) => {
				if (error.response?.status === 403) {
          showSignIn();
				}
				console.error('saveCurrencyCode - error: ' + error);
				// setbuttonLoading(false)
			});
  }

  const handleSubmitClicked = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();

    if (!authCurrentUser) {
      setSubmitPending(true);
      showSignIn();
      return;
    }

		setbuttonLoading(true);
    await performSubmit();
    setbuttonLoading(false);
    window.scrollTo(0, 0);
  }

  const performSubmit = useCallback(async () => {
    if (!authCurrentUser) {
      // should never get here
      return;
    }

		// authMiddleWare(navigate);
		const authToken = await getOrRefreshIdToken(authCurrentUser);
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		const formRequest = {
			product: product,
      currencyCode: submissionCurrencyCode,
      cost,
      placeNameMain: placeNameMain,
      placeNameSecondary: placeNameSecondary,
      placeId: selectedGooglePlace?.placeId,
      _geoloc: {
        lat: selectedGooglePlace?.geometry?.location?.lat(),
        lng: selectedGooglePlace?.geometry?.location?.lng(),
      },
      includesTaxes,
      includesTipOrFees,
      includesCoupons,
      includesInsurance,
      isQuote,
      byBusiness,
      // notes,
      intlLocale
		};
		axios
			.post('/api/bill', formRequest)
			.then(() => {
				setbuttonLoading(false);
        setCardShown(false);
        setProduct('');
        setCost(undefined);
        setPlaceNameMain('');
        setPlaceNameSecondary('');
        setSelectedGooglePlace(undefined);
        setIncludesTaxes(false);
        setIncludesTipOrFees(false);
        setIncludesCoupons(false);
        setIncludesInsurance(false);
        setIsQuote(false);
        setByBusiness(false);
        enqueueSnackbar('Submitted!', { variant: 'success' });
        window.scrollTo(0, 0);
        // setCardShown(true);

        reportAction("submit_success", "engagement");
			})
			.catch((error) => {
				if (error.response?.status === 403) {
					// navigate('/signin');
          showSignIn();
				}
				console.error(error);
        reportAction("submit_error", "engagement");
			});
	}, [authCurrentUser, byBusiness, cost, includesCoupons, includesInsurance, includesTaxes, includesTipOrFees, intlLocale, isQuote, placeNameMain, placeNameSecondary, product, selectedGooglePlace?.geometry?.location, selectedGooglePlace?.placeId, showSignIn, submissionCurrencyCode]);

  useEffect(() => {
    const doSubmit = async () => {
      setbuttonLoading(true);
      await performSubmit();
      setSubmitPending(false);
      setbuttonLoading(false);
      window.scrollTo(0, 0);
    }

    if (authCurrentUser && submitPending) {
      doSubmit();
    }
  }, [authCurrentUser, submitPending, performSubmit])

  // const render = (status: Status) => {
  //   if (status === Status.FAILURE) return <ErrorComponent />;
  //   return <CircularProgress size={150} className={classes.uiProgress} sx={{
  //     position: 'fixed',
  //     zIndex: '1000',
  //     height: '31px',
  //     width: '31px',
  //     left: '50%',
  //     top: '35%'
  //   }} />;
  // };

  const getCurrencySymbol = (code: string) => {
    return getSymbolFromCurrency(code);
  }

  return (
    <Container
      component="main"
      className={classes.content}
      maxWidth="sm"
      sx={{
        marginTop: 2,
      }}
    >
      <IntlProvider locale={navigator.language}>
        {/* <Wrapper apiKey={placesApiKey} libraries={['places']} render={render}> */}

          <Dialog open={currencyDialogOpen}>
            <CurrencyDialog
              currentCurrencyCode={userCurrencyCode}
              handleSelectedCurrencyCode={selectedCurrencyCode => {
                saveCurrencyCode(selectedCurrencyCode);
                setCurrencyDialogOpen(false);
              }}
              handleCancel={() => setCurrencyDialogOpen(false)}
            />
          </Dialog>

          {/* {!authCurrentUser &&
            <Box mt={3}>
              <Typography component="h2" variant="subtitle1">
                <Link component='button' onClick={() => showSignIn()} sx={{pb: "3px", fontWeight: 700}}>Sign in</Link> or <Link component='button' onClick={() => showSignUp()} sx={{pb: "3px", fontWeight: 700}}>create an account</Link>.
              </Typography>
              <Typography component="p" variant="body2" mt={2}>
                With an account, you can
              </Typography>
              <Typography variant="body2">
              <List dense={true} sx={{ listStyleType: 'disc', pl: 2 }}>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  post a bill that you paid
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  post a quote you received
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  or, if you're a business, post an amount you charged a customer.
                </ListItem>
              </List>
              </Typography>

              <Typography component="p" variant="body2" mt={2}>
                Creating an account is quick, easy, and absolutely free.
              </Typography>
            </Box>
          } */}

            <Box sx={{
              // maxWidth: "450px"
            }}>
              <SnackbarProvider maxSnack={2} />

              <Box display='flex' flexDirection='row' alignItems='center' marginBottom={1}>
                <Typography component="h2" variant="h6" fontWeight={700}>
                  {/* Tell others what you paid. */}
                  {/* What did they charge you? */}
                  {/* What were you charged for something? */}
                  {/* What were you charged? */}
                  {/* What did you charge recently? */}
                  {/* How much did it cost? */}
                  Submit a bill
                </Typography>
                <IconButton
                  color="primary"
                  aria-label="cost help"
                  sx={{marginLeft: 1}}
                  onClick={() => setSubmitInfoModalOpen(true)}
                >
                  <InfoIcon />
                </IconButton>
                <Modal
                  keepMounted
                  open={submitInfoModalOpen}
                  onClose={() => setSubmitInfoModalOpen(false)}
                  aria-labelledby="keep-mounted-modal-title"
                  aria-describedby="keep-mounted-modal-description"
                >
                  <SubmitInfoModal handleClose={() => setSubmitInfoModalOpen(false)}/>
                </Modal>
              </Box>

              <Fade
                in={cardShown}
                onExited={() => setCardShown(true)}
                style={{ transitionDelay: cardShown ? '0ms' : '0ms' }}
              >

              <Card className={clsx(classes.root, classes)}>
                <form autoComplete="off" noValidate>
                  <CardContent>
                    <Box>
                      <ProductAutocomplete
                        initialProduct=""
                        product={product}
                        setProduct={setProduct}
                      />
                    </Box>
                    <Box mt={3}>
                      <PlaceAutocomplete
                        businessOnly={true}
                        placeNameMain={placeNameMain}
                        placeNameSecondary={placeNameSecondary}
                        setPlaceNameMain={newPlaceNameMain => setPlaceNameMain(newPlaceNameMain)}
                        setPlaceNameSecondary={newPlaceNameSecondary => setPlaceNameSecondary(newPlaceNameSecondary)}
                        selectedGooglePlace={selectedGooglePlace}
                        setSelectedGooglePlace={newSelectedGooglePlace => setSelectedGooglePlace(newSelectedGooglePlace)}
                        autocompleteService={autocompleteService}
                      />
                    </Box>
                    <Box mt={3}>
                      <TextField
                        label="Cost"
                        name="cost"
                        variant="outlined"
                        type="number"
                        value={cost || ""}
                        onChange={event => {
                          // console.log('onChange: ' + event.target.value);
                          const matched = event.target.value.match(/\d+\.?\d*/);
                          if (matched) {
                            const limited = matched[0].slice(0, MAX_COST_CHARS);
                            setCost(Math.max(0, Number(limited)));
                          } else {
                            setCost(undefined);
                          }

                          // const limited = Math.max(0, parseInt(event.target.value)).toString().slice(0, MAX_COST_DIGITS)
                          // setCost(Number(limited));
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                            >
                              <IconButton
                                aria-label="currency symbol"
                                onClick={() => {setCurrencyDialogOpen(true)}}
                              >
                                <Typography color='primary'>
                                  {getCurrencySymbol(submissionCurrencyCode)}
                                </Typography>
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: cost !== undefined && (
                            <IconButton
                              aria-label="clear cost input"
                              onClick={() => setCost(undefined)}
                            >
                              <CloseIcon fontSize='small' />
                            </IconButton>
                          )
                        }}
                        sx={{
                          maxWidth: "160px",
                          boxShadow: "0 2px 10px #00000020"
                        }}
                      />
                    </Box>
                    <Box mt={3}>
                      <Typography fontSize="0.8rem">
                        Check any that apply:
                      </Typography>
                      <FormGroup sx={{ mt: 1 }}>
                        <FormControlLabel
                          control={<Checkbox value='' checked={includesTaxes} onChange={event => setIncludesTaxes(event.target.checked)} />}
                          label="Includes taxes"
                          sx={{
                            marginRight: 0
                          }}
                          componentsProps={{
                            typography: { fontSize: "0.8rem" }
                          }}
                        />
                        <FormControlLabel
                          control={<Checkbox value='' checked={includesTipOrFees} onChange={event => setIncludesTipOrFees(event.target.checked)} />}
                          label="Includes tip or other fees"
                          sx={{
                            marginRight: 0
                          }}
                          componentsProps={{
                            typography: { fontSize: "0.8rem" }
                          }}
                        />
                        <FormControlLabel
                          control={<Checkbox value='' checked={includesCoupons} onChange={event => setIncludesCoupons(event.target.checked)} />}
                          label="Includes a coupon discount"
                          sx={{
                            marginRight: 0
                          }}
                          componentsProps={{
                            typography: { fontSize: "0.8rem" }
                          }}
                        />
                        <FormControlLabel
                          control={<Checkbox value='' checked={includesInsurance} onChange={event => setIncludesInsurance(event.target.checked)} />}
                          label="Includes insurance portion"
                          sx={{
                            marginRight: 0
                          }}
                          componentsProps={{
                            typography: { fontSize: "0.8rem" }
                          }}
                        />
                        <FormControlLabel
                          control={<Checkbox value='' checked={isQuote} onChange={event => setIsQuote(event.target.checked)} />}
                          label="This was just a quote or advertised price"
                          sx={{
                            marginRight: 0
                          }}
                          componentsProps={{
                            typography: { fontSize: "0.8rem" }
                          }}
                        />
                        <FormControlLabel
                          control={<Checkbox value='' checked={byBusiness} onChange={event => setByBusiness(event.target.checked)} />}
                          label="I'm the business, not a customer"
                          // label="I billed this. I'm not a customer."
                          sx={{
                            marginRight: 0
                          }}
                          componentsProps={{
                            typography: { fontSize: "0.8rem" }
                          }}
                        />
                      </FormGroup>
                    </Box>
                  </CardContent>
                </form>
              </Card>
            </Fade>

            <Button
              color="primary"
              variant="contained"
              type="submit"
              className={classes.submitButton}
              onClick={handleSubmitClicked}
              disabled={
                buttonLoading ||
                !submissionCurrencyCode ||
                !product ||
                !cost ||
                !placeNameMain ||
                !selectedGooglePlace
              }
              sx={{
                marginTop: '10px'
              }}
            >
              Submit bill
              {buttonLoading && <CircularProgress size={30} className={classes.progress} sx={{
                position: 'absolute'
              }} />}
            </Button>
          </Box>

        {/* </Wrapper> */}
      </IntlProvider>
    </Container>
  );
}

export default AddBill;
