import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Typography, Button } from '@mui/material';
import billdbMapImg from "./images/blog/billdb_map.webp";
import hundredDollarBillImg from "./images/blog/100_dollar_bill.webp";
import * as metrics from "../util/metrics";
import BlogPostMission from './blogPosts/mission';
// import BlogPostReward from './blogPosts/reward';
import BlogPostMiSight from './blogPosts/misight';
import BlogPostPizza from './blogPosts/pizza';
import BlogPostInvisalign from './blogPosts/invisalign';
import BlogPostHousepainters from './blogPosts/housepainters';
import { useLocation } from 'react-router-dom';

const classes = { content: "content"}

export type BlogProps = {
  openRewardInfoModal: () => void,
}

const Blog = ({ openRewardInfoModal }: BlogProps) => {
  const [initialLocation] = useState(useLocation());

  useEffect(() => {
    window.scrollTo(0, 0);
    metrics.reportPageLoad("Blog", window.location.href,  window.location.pathname);
  }, [])

  let blogPost = "";
  switch (initialLocation.pathname) {
    case "/blog/cost_to_paint_my_house":
      blogPost ="housepainters";
      break;
    case "/blog/invisalign_cost":
      blogPost ="invisalign";
      break;
    case "/blog/seattle_pizza_prices":
      blogPost ="pizza";
      break;
    case "/blog/misight_myopia_cost_saving":
      blogPost ="misight";
      break;
    case "/blog/billdb_mission":
      blogPost ="mission";
      break;
  }

  return (
    <Box component="main" className={classes.content} sx={{
      flexGrow: 1,
      pt: 2,
      pb: 2,
      width: "100%"
    }}>
      <Container
        maxWidth='md'
      >
        {blogPost === "housepainters" &&
          <Box>
            <Box mt={2}>
              <BlogPostHousepainters />
            </Box>
            <Box mt={8}>
              <BlogPostInvisalign />
            </Box>
            <Box mt={8}>
              <BlogPostPizza />
            </Box>
            <Box mt={8}>
              <BlogPostMiSight />
            </Box>
            <Box mt={8}>
              <BlogPostMission />
            </Box>
          </Box>
        }

        {blogPost === "invisalign" &&
          <Box>
            <Box mt={2}>
              <BlogPostInvisalign />
            </Box>
            <Box mt={8}>
              <BlogPostHousepainters />
            </Box>
            <Box mt={8}>
              <BlogPostPizza />
            </Box>
            <Box mt={8}>
              <BlogPostMiSight />
            </Box>
            <Box mt={8}>
              <BlogPostMission />
            </Box>
          </Box>
        }


        {blogPost === "pizza" &&
          <Box>
            <Box mt={2}>
              <BlogPostPizza />
            </Box>
            <Box mt={8}>
              <BlogPostHousepainters />
            </Box>
            <Box mt={8}>
              <BlogPostInvisalign />
            </Box>
            <Box mt={8}>
              <BlogPostMiSight />
            </Box>
            <Box mt={8}>
              <BlogPostMission />
            </Box>
          </Box>
        }


        {blogPost === "misight" &&
        <Box>
          <Box mt={2}>
            <BlogPostMiSight />
          </Box>
          <Box mt={8}>
            <BlogPostHousepainters />
          </Box>
          <Box mt={8}>
            <BlogPostInvisalign />
          </Box>
          <Box mt={8}>
            <BlogPostPizza />
          </Box>
          <Box mt={8}>
            <BlogPostMission />
          </Box>
        </Box>
      }

        {blogPost === "mission" &&
          <Box mt={2}>
            <BlogPostMission />
          </Box>
        }

        {blogPost === "" &&
          <Box>
            <Box mt={2}>
              <BlogPostHousepainters />
            </Box>
            <Box mt={8}>
              <BlogPostInvisalign />
            </Box>
            <Box mt={8}>
              <BlogPostPizza />
            </Box>
            <Box mt={8}>
              <BlogPostMiSight />
            </Box>
            <Box mt={8}>
              <BlogPostMission />
            </Box>
          </Box>
        }
      </Container>
    </Box>
  );
}

export default Blog;