import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import * as serviceWorker from './serviceWorker';

import {HelmetProvider, Helmet} from "react-helmet-async";


const helmetContext = {};

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <Helmet>
        <title>BillDB - How much were you charged?</title>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:site_name" content="BillDB" />
        <meta name="theme-color" content="#00000" />
        <meta name="description" content="Search for prices submitted by the BillDB community for products and services from businesses near you" />
        <meta property="og:title" content="BillDB - Find out what it costs" />
        <meta property="og:description" content="Search for prices submitted by the BillDB community for products and services from businesses near you." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://billdb.com/" />
        <meta property="og:image" content="https://billdb.com/billdb_icon_1024.png" />
        <link rel="canonical" href="https://billdb.com/" />
        <link rel="apple-touch-icon" href="https://billdb.com/apple-touch-icon.png" />
        <link rel="manifest" href="https://billdb.com/manifest.json" />
        <link rel="icon" type="image/png" sizes="32x32" href="https://billdb.com/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="https://billdb.com/favicon-16x16.png" />
      </Helmet>
    </HelmetProvider>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
