import React, { useEffect } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import { debounce } from '@mui/material/utils';
import SearchIcon from '@mui/icons-material/Search';

import {
  AutocompleteOptions,
  AutocompleteState,
  createAutocomplete,
} from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import { Hit } from '@algolia/client-search';
import algoliasearch from 'algoliasearch/lite';

import { htmlDecode } from '../util/inputValidation';

const autocompleteService = {
  current: algoliasearch('U6C6TNQKO0', 'e0462085fdf13ab65c186233db15d4a5')
};

interface ProductResult {
  productNormalized: string;
  highlightResult?: string;
}

type AutocompleteItem = Hit<{
  productNormalized: string;
  product: string;
  cost: number;
  placeId: string;
  latitude: number;
  longitude: number;
  dateSubmitted: number;
}>;

export type ProductAutocompleteProps = {
  initialProduct: string,
  product: string,
  setProduct: (newProduct: string) => void,
  onProductEntered?: () => void,
}

const ProductAutocomplete = ({ initialProduct, product, setProduct, onProductEntered: onProductEntered } : Partial<AutocompleteOptions<AutocompleteItem>> & ProductAutocompleteProps) => {
  // const [value, setValue] = React.useState<ProductResult | null>(null);
  const [value, setValue] = React.useState<ProductResult | null>(product ? {productNormalized: product} : null);
  const [options, setOptions] = React.useState<readonly ProductResult[]>([]);

  const [autocompleteState, setAutocompleteState] = React.useState<
    AutocompleteState<AutocompleteItem>
  >({
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: '', // not sure if this has any effect
    activeItemId: null,
    status: 'idle',
  });

  // This clears the input field when the parent clears the product state (after submission)
  useEffect(() => {
    if (product.length === 0) {
      setValue({
        productNormalized: ""
      });
    }
  }, [product])

  const myAutocomplete = React.useMemo(() =>
    createAutocomplete<
      AutocompleteItem,
      React.BaseSyntheticEvent,
      React.MouseEvent,
      React.KeyboardEvent
    >({
      onStateChange({ state }) {
        setAutocompleteState(state);
      },
      onSubmit({ state }) {
        setProduct(state.query);
      },
      initialState: {
       query: initialProduct,
        // query: ""
      },
      insights: true,
      autoFocus: true,
      getSources() {
        // console.log("ProductAutocomplete - 3 (getSources)");

        return [
          {
            sourceId: 'products',
            getItems({ query }) {
              return getAlgoliaResults({
                searchClient: autocompleteService.current,
                queries: [
                  {
                    indexName: 'billdb-index-1_productNormalized_dedupe',
                    query,
                    params: {
                      hitsPerPage: 5,
                    },
                  },
                ],
              });
            },
            onSelect: ({ item, setQuery, setIsOpen, refresh }) => {
              // console.log("onSelect - item: " + JSON.stringify(item));
              const query = item.productNormalized;
              // console.log(`onSelect - query: ${query}`);
              setQuery(query);
              setIsOpen(false);
              refresh(); // refreshes the local search results cache
              setProduct(query);
            },
          },
        ];
      },
//      ...props,
    }),
    // [setProduct, product]
    [setProduct, initialProduct]
  );

  useEffect(() => {
    let newOptions: readonly ProductResult[] = [];

    if (value) {
      newOptions = [value];
    }

    const results: ProductResult[] = autocompleteState.collections[0]?.items.map(item => {
      const productResult: ProductResult = {
        productNormalized: item.productNormalized,
        highlightResult: item._highlightResult?.productNormalized?.value
      }

      return productResult;
    });

    if (results) {
      newOptions = [...newOptions, ...results];
    }

    setOptions(newOptions);
  }, [autocompleteState, value]);


  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          // callback: (results?: readonly PlaceType[]) => void,
        ) => {
          // console.log('fetch');
          myAutocomplete.setQuery(request.input);
          // myAutocomplete.setQuery(encodeURIComponent(request.input));
          myAutocomplete.refresh();
          // (autocompleteService.current as any).getPlacePredictions(
          //   request,
          //   callback,
          // );
        },
        500,
      ),
    [myAutocomplete],
  );

  React.useEffect(() => {
    // let active = true;

    if (!autocompleteService.current && (window as any).algoliasearch) {
      autocompleteService.current = new (
        window as any
      ).algoliasearch(
        'U6C6TNQKO0',
        'e0462085fdf13ab65c186233db15d4a5'
      );
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (product === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    // console.log(`encodeURIComponent(product): ${encodeURIComponent(product)}`);

    // fetch({ input: encodeURIComponent(product) });
    fetch({ input: product });

    return () => {
      // active = false;
    };
  }, [value, fetch, product]);

  // console.log('productAutocomplete - render');

  return (
    <Autocomplete
      fullWidth
      freeSolo
      id="product-input"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.productNormalized
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      blurOnSelect
      value={value}
      // noOptionsText="No product or service"
      onChange={(event: any, newValue: ProductResult | string | null) => {
        // console.log('Autocomplete - onChange');
        let productResult: ProductResult | null = {
          productNormalized: ""
        };

        if (typeof(newValue) === 'string') {
          productResult.productNormalized = htmlDecode(newValue) ?? "";
          // productResult.productNormalized = decodeURIComponent(newValue) ?? "";
        } else {
          productResult = {
            productNormalized: htmlDecode(newValue?.productNormalized) ?? "",
            // productNormalized: decodeURIComponent(newValue?.productNormalized ?? ""),
            highlightResult: newValue?.highlightResult
          }
        }

        setOptions(productResult ? [productResult, ...options] : options);
        setValue(productResult);
        setProduct(productResult?.productNormalized || "");
      }}
      onInputChange={(event, newInputValue) => {
        // console.log('Autocomplete - onInputChange');
        setProduct(newInputValue || "");
      }}
      onBlur={() => {
        if (onProductEntered) {
          onProductEntered();
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Product or Service"
          // defaultValue="current location"
          fullWidth
          InputProps={{
            ...params.InputProps,
            placeholder: "eye exam, oil change, etc.",
            startAdornment: (
              <InputAdornment
                position="start"
              >
                <IconButton
                  aria-label="product"
                  onClick={() => {
                    // console.log('onclick - value: ' + JSON.stringify(value));
                    setProduct(htmlDecode(value?.productNormalized) ?? "");
                    // setProduct(decodeURIComponent(value?.productNormalized ?? ""));
                    // handleClickCurrentLocation();
                  }}
                >
                  <SearchIcon color="primary" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ boxShadow: "0 2px 10px #00000020" }}
          onKeyDown={e => {
            if (e.code === 'Enter') {
              // blur
            }
            if (e.code === 'Enter' && onProductEntered) {
              onProductEntered();
            }
          }}
        />
      )}
      renderOption={(props, option) => {
        let partsTagged: {text: string, highlight: boolean}[] = [];

        if (typeof(option) === 'string') {
          partsTagged = [{
            text: htmlDecode(option) ?? "",
            // text: option ?? "",
            highlight: false
          }]
        } else {
          const decodedHighlight = htmlDecode(option.highlightResult ?? "");
          // const decodedHighlight = option.highlightResult ?? "";

          // console.log(`decodedHighlight: ${decodedHighlight}`);

          // console.log(`renderOption - option: ${JSON.stringify(option)}`)
          const parts = decodedHighlight?.split(/(?:__aa-highlight__|__\/aa-highlight__)/).filter(i => i) ?? [];
          let highlightNextPart = false;
          if (decodedHighlight) {
            highlightNextPart = decodedHighlight.startsWith("__aa-highlight__");
          }
          // console.log("parts: " + JSON.stringify(parts))
          partsTagged = parts.map(part => {
            const tagged = {
              // text: part ?? "",
              text: part ?? "",
              highlight: highlightNextPart
            }
            highlightNextPart = !highlightNextPart;
            return tagged;
          });
        }

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {partsTagged.map((tagged, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: tagged.highlight ? 'bold' : 'regular' }}
                  >
                    {tagged.text}
                  </Box>
                ))}
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

export default ProductAutocomplete;
