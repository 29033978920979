import React, { useEffect } from "react";
import { Box, Typography, Link } from "@mui/material";
import image from "../images/blog/misight.webp";
import image2 from "../images/blog/misight_seattle.webp";
import * as metrics from "../../util/metrics";

const BlogPostMiSight = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    metrics.reportPageLoad("Blog", window.location.href,  window.location.pathname);
  }, [])

  return (
    <Box>
      <Link href="/blog/misight_myopia_cost_saving" underline="hover">
        <Typography
          component="h2"
          variant="h5"
          mt={3}
          mb={1}
          fontSize='2rem'
          color='#242424'
        >
          I'm Saving $12,800 by Switching Optometrists
        </Typography>
      </Link>
      <Typography
        pb={1}
      >
        <time>November 9, 2023</time>
      </Typography>

      <Box
        mt={3}
        mb={2}
      >
        <img
          src={image}
          alt="Optometrist phoropter with dollar signs"
          loading="lazy"
          style={{
            height: "300px",
            objectFit: "cover",
            width: "100%"
          }}
        />
      </Box>

      <Typography component="p" mt={2}>
        A little research is going to save me a <b>ton</b> of money, and I suspect most people in a situation like mine are missing out.
        As my family's experience with special contact lenses shows, any time that pricing is opaque is one where you might be paying more than you should.
        </Typography>
      <Typography component="p" mt={2}>
        A little background: My daughter needs <Link href="https://pubmed.ncbi.nlm.nih.gov/26513719/" target="_blank" rel="noopener">myopia control</Link> to avoid ending up with severe nearsightedness as an adult.
        It turns out that modern science has come up with multiple options for this, including <Link href="https://www.nih.gov/news-events/news-releases/low-dose-atropine-eyedrops-no-better-placebo-slowing-myopia-progression" target="_blank" rel="noopener">atropine</Link> eye drops, <Link href="https://www.aao.org/eye-health/glasses-contacts/what-is-orthokeratology" target="_blank" rel="noopener">Ortho-K</Link> overnight contact lenses, and <Link href="https://misight.com/" target="_blank" rel="noopener">MiSight</Link> daily lenses.
      </Typography>
      <Typography component="p" mt={2}>
        Given my daughter's sports-centric lifestyle, we decided to go with MiSight.
        After some initial trials and tribulations with getting comfortable putting them in every day, they've been working out pretty well.
        That said, they do have a downside—<b>they most definitely aren't cheap.</b>
      </Typography>
      <Typography component="p" mt={2}>
        We paid roughly $2,000 for the first year, which included the fitting, checkups, and lenses.
        $1,000 of that was for the year's supply of contact lenses, and the rest was for the fitting and checkups.
      </Typography>
      <Typography component="p" mt={2}>
        When year 2 came along, and treatment became a consideration for our son as well, I decided to shop around a bit and see what kind of a deal we had been getting.
        As it turns out, not a particularly good one...
      </Typography>
      <Typography component="p" mt={2}>
        I called several offices in the Seattle area, and I found that prices for the first year ranged from $1,500 to $2,100.
      </Typography>

      <Box display="flex" flexDirection="column" justifyContent="center">
        <Box
          mt={3}
          alignSelf="center"
        >
          <img
            src={image2}
            alt="misight prices"
            loading="lazy"
            style={{
              height: "300px",
              maxWidth: "100%",
            }}
          />
        </Box>
        <Typography mt={0} fontSize="0.8rem" sx={{
          alignSelf: "center"
        }}>
          <i>Quotes received over the phone for MiSight (<Link href="/search?q=misight" target="_blank" rel="noopener">source</Link>)</i>
        </Typography>
      </Box>

      <Typography component="p" mt={2}>
        That's a pretty big discrepancy.
      </Typography>
      <Typography component="p" mt={2}>
        Not only that, but some—but not all—offices charge a lower rate for year 2 and beyond.
        The place we had been going to, for example, was going to charge $2,100 for the next year.
        That's quite a contrast with another local optometrist who would charge a more reasonable $1,300.
      </Typography>
      <Typography component="p" mt={2}>
        Now let's think about what that means for a child who wears them from age 10-18.
      </Typography>
      <Typography component="p" mt={2}>
        <b>A price difference of $800 per year would add up to a whopping $6,400.</b>
      </Typography>
      <Typography component="p" mt={2}>
        Two kids?
        We'd be needlessly spending a total of <b>$12,800</b> more at the first vs. second office.
        Why the discrepancy? Would we be getting a higher level of service for that extra money?
        Not that I could tell.
        In fact, I was underwhelmed with our checkups at the original, higher-cost office and would have changed offices anyway.
      </Typography>
      <Typography component="p" mt={2}>
        The lesson here?
        It really pays to comparison shop, perhaps <i>especially</i> for things we don't often consider doing it for, such as our healthcare.
      </Typography>
      <Typography component="p" mt={2}>
        My findings for year 1 of MiSight at nearby providers are available <Link href="/search?q=misight" target="_blank" rel="noopener">here</Link>.
        If you have any optometrist quotes or bills, please consider submitting as well.
        Let's make comparison shopping easy!
      </Typography>
      <Typography component="p" mt={2}>
        <i>A final note: One thing that really struck me was that the optometrist offices I called didn't seem especially accustomed to people calling and asking for prices.
        Maybe that's a reason for the severe price disparity.
        This realization—that service providers don't necessarily compete very much on price—is the primary motivator for BillDB.</i>
      </Typography>
    </Box>
  );
}

export default BlogPostMiSight;