

export const reportPageLoad = (title: string, location: string, path: string) => {
  gtag('event', 'page_view', {
    page_title: title,
    page_location: location,
    page_path: path,
    send_to: 'G-3CLLK2QW52'
  })
}

export const reportAction = (action: string, category: string, label?: string, value?: number) => {
  gtag('event', action, {
    'event_category': category,
    // 'event_label': label,
    // 'value': value
  });
}
